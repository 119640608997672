/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 0;
  }
  
  .flight-offers-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  p {
    font-size: 16px;
    color: #333;
  }
  
  .box {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f9f9fc;
    border: 1px solid #e3e3e8;
    border-radius: 8px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .box:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
  }
  
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10px;
  }
  
 .flight-offers-container .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 10px;
  }
  
  .airline-box {
    text-align: center;
  }
  
  .airline-box img {
    max-height: 50px;
    margin-bottom: 10px;
  }
  
  .first-row h3 {
    font-size: 18px;
    font-weight: bold;
    color: #444;
    margin: 5px 0;
  }
  
  .first-row h4 {
    font-size: 16px;
    color: #555;
  }
  
  .first-row .stop {
    font-size: 14px;
    background-color: #ff9800;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px;
    display: inline-block;
  }
  
  .f-icon img {
    max-width: 30px;
  }
  
  /* Flight Timing Section */
  .time {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .time .icon {
    font-size: 24px;
    color: #ff9800;
    margin-right: 10px;
  }
  
  .time div {
    font-size: 14px;
    color: #555;
  }
  
  .time div span {
    font-size: 16px;
    font-weight: bold;
    color: #444;
  }
  
  /* Price Section */
  .price-info {
    text-align: center;
    background-color: #f4f4f9;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #e3e3e8;
  }
  
  .price-info .price {
    font-size: 24px;
    color: #4caf50;
    font-weight: bold;
  }
  
  .price-info small {
    font-size: 14px;
    color: #777;
  }
  
  /* Book Now Button */
  .action {
    text-align: right;
    margin-top: 7px;
  }
  .action a {
    display: inline-block;
    background-color: #4caf50;
    color: #fff !important;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .action a:hover {
    background-color: #388e3c;
  }
  
  /* Error Message */
  .flight-offers-container p[style*='color: red'] {
    font-size: 14px;
    background-color: #ffe6e6;
    border: 1px solid #ffcccc;
    padding: 10px;
    border-radius: 5px;
  }
  
  .itinerary {
    width: 44%;
    display: inline-grid;
    text-align: left;
    margin-right: 6%;
  }
  .segment {
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .flight-offer {
    background: #f9f9f9;
  }
  .bookNowBtn  {
    padding: 10px 23px !important;
    border: none !important;
    border-radius: 5px !important;
  }
  /* Responsive Styles */
  @media (max-width: 768px) {
    .flight-offers-container .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  