
@media all{
    *,:after,:before{box-sizing:border-box;}
    h2,h3{margin-block-start:.5rem;margin-block-end:1rem;font-family:inherit;font-weight:500;line-height:1.2;color:inherit;}
    h2{font-size:2rem;}
    h3{font-size:1.75rem;}
    @media print{
    *,:after,:before{background:transparent!important;color:#000!important;box-shadow:none!important;text-shadow:none!important;}
    h2,h3{orphans:3;widows:3;}
    h2,h3{-moz-column-break-after:avoid;break-after:avoid;}
    }
    label{display:inline-block;line-height:1;vertical-align:middle;}
    button,input,select,textarea{font-family:inherit;font-size:1rem;line-height:1.5;margin:0;}
    input[type=email],input[type=text],select,textarea{width:100%;border:1px solid #666;border-radius:3px;padding:.5rem 1rem;transition:all .3s;}
    input[type=email]:focus,input[type=text]:focus,select:focus,textarea:focus{border-color:#333;}
    button,input{overflow:visible;}
    button,select{text-transform:none;}
    [type=submit],button{width:auto;-webkit-appearance:button;}
    [type=submit],button{display:inline-block;font-weight:400;color:#c36;text-align:center;white-space:nowrap;-webkit-user-select:none;-moz-user-select:none;user-select:none;background-color:transparent;border:1px solid #c36;padding:.5rem 1rem;font-size:1rem;border-radius:3px;transition:all .3s;}
    [type=submit]:focus:not(:focus-visible),button:focus:not(:focus-visible){outline:none;}
    [type=submit]:focus,[type=submit]:hover,button:focus,button:hover{color:#fff;background-color:#c36;text-decoration:none;}
    [type=submit]:not(:disabled),button:not(:disabled){cursor:pointer;}
    textarea{overflow:auto;resize:vertical;}
    select{display:block;}
    li,ul{margin-block-start:0;margin-block-end:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent;}
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.24.6 ; media=all */
    @media all{
    .elementor-screen-only{position:absolute;top:-10000em;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);border:0;}
    .elementor *,.elementor :after,.elementor :before{box-sizing:border-box;}
    .elementor .elementor-background-overlay{height:100%;width:100%;top:0;left:0;position:absolute;}
    .elementor-element{--flex-direction:initial;--flex-wrap:initial;--justify-content:initial;--align-items:initial;--align-content:initial;--gap:initial;--flex-basis:initial;--flex-grow:initial;--flex-shrink:initial;--order:initial;--align-self:initial;flex-basis:var(--flex-basis);flex-grow:var(--flex-grow);flex-shrink:var(--flex-shrink);order:var(--order);align-self:var(--align-self);}
    .elementor-element:where(.e-con-full,.elementor-widget){flex-direction:var(--flex-direction);flex-wrap:var(--flex-wrap);justify-content:var(--justify-content);align-items:var(--align-items);align-content:var(--align-content);gap:var(--gap);}
    
    .elementor-col-50 {width:50%}
    .elementor-col-100 {width:100%}
    .elementor-button {
        width: 100%;
      }
    @media (max-width:767px){
    .elementor-mobile-align-left{text-align:left;}
    }
    .elementor-section{position:relative;}
    .elementor-section .elementor-container{display:flex;margin-right:auto;margin-left:auto;position:relative;}
    @media (max-width:1024px){
    .elementor-section .elementor-container{flex-wrap:wrap;}
    }
    .elementor-section.elementor-section-boxed>.elementor-container{max-width:1140px;}
    .elementor-widget-wrap{position:relative;width:100%;flex-wrap:wrap;align-content:flex-start;}
    .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap{display:flex;}
    .elementor-widget-wrap>.elementor-element{width:100%;}
    .elementor-widget{position:relative;}
    .elementor-widget:not(:last-child){margin-bottom:20px;}
    .elementor-column{position:relative;min-height:1px;display:flex;}
    .elementor-column-gap-default>.elementor-column>.elementor-element-populated{padding:10px;}
    @media (min-width:768px){
    .elementor-column.elementor-col-50{width:50%;}
    .elementor-column.elementor-col-100{width:100%;}
    }
    @media (max-width:767px){
    .elementor-column{width:100%;}
    }
    .elementor-form-fields-wrapper{display:flex;flex-wrap:wrap;}
    .elementor-field-group{flex-wrap:wrap;align-items:center;}
    .elementor-field-group.elementor-field-type-submit{align-items:flex-end;}
    .elementor-field-group .elementor-field-textual{width:100%;max-width:100%;border:1px solid #69727d;background-color:transparent;color:#1f2124;vertical-align:middle;flex-grow:1;}
    .elementor-field-group .elementor-field-textual:focus{box-shadow:inset 0 0 0 1px rgba(0,0,0,.1);outline:0;}
    .elementor-field-group .elementor-field-textual::placeholder{color:inherit;font-family:inherit;opacity:.6;}
    .elementor-field-group .elementor-select-wrapper{display:flex;position:relative;width:100%;}
    .elementor-field-group .elementor-select-wrapper select{-webkit-appearance:none;-moz-appearance:none;appearance:none;color:inherit;font-size:inherit;font-family:inherit;font-weight:inherit;font-style:inherit;text-transform:inherit;letter-spacing:inherit;line-height:inherit;flex-basis:100%;padding-inline-end:20px;}
    .elementor-field-group .elementor-select-wrapper:before{content:"\e92a";font-family:eicons;font-size:15px;position:absolute;top:50%;transform:translateY(-50%);right:10px;pointer-events:none;text-shadow:0 0 3px rgba(0,0,0,.3);}
    .elementor-field-label{cursor:pointer;}
    .elementor-field-textual{line-height:1.4;font-size:15px;min-height:40px;padding:5px 14px;border-radius:3px;}
    .elementor-field-textual.elementor-size-md{font-size:16px;min-height:47px;padding:6px 16px;border-radius:4px;}
    .elementor-button-align-stretch .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button{flex-basis:100%;}
    .elementor-form .elementor-button{padding-block-start:0;padding-block-end:0;border:none;}
    .elementor-form .elementor-button-content-wrapper,.elementor-form .elementor-button>span{display:flex;justify-content:center;flex-direction:row;gap:5px;}
    .elementor-form .elementor-button.elementor-size-sm{min-height:40px;}
    .elementor-element .elementor-widget-container{transition:background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);}
    .elementor-heading-title{padding:0;margin:0;line-height:1;}
    .elementor-button{display:inline-block;line-height:1;background-color:#69727d;font-size:15px;padding:12px 24px;border-radius:3px;color:#fff;fill:#fff;text-align:center;transition:all .3s;}
    .elementor-button:focus,.elementor-button:hover,.elementor-button:visited{color:#fff;}
    .elementor-button-content-wrapper{display:flex;justify-content:center;flex-direction:row;gap:5px;}
    .elementor-button-text{display:inline-block;}
    .elementor-button span{text-decoration:inherit;}
    .elementor .elementor-element ul.elementor-icon-list-items{padding:0;}
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/uploads/elementor/css/post-11.css?ver=1731781304 ; media=all */
    @media all{
    .elementor-kit-11 button,.elementor-kit-11 .elementor-button{background-color:var( --e-global-color-accent );font-family:var( --e-global-typography-1e647e3-font-family ), Sans-serif;font-size:var( --e-global-typography-1e647e3-font-size );font-weight:var( --e-global-typography-1e647e3-font-weight );line-height:var( --e-global-typography-1e647e3-line-height );color:var( --e-global-color-c03bcf0 );box-shadow:0px 0px 50px 0px rgba(29.999999999999986, 194.00000000000006, 139.00000000000003, 0.2);border-radius:50px 50px 50px 50px;padding:27px 47px 27px 47px;}
    .elementor-kit-11 button:hover,.elementor-kit-11 button:focus,.elementor-kit-11 .elementor-button:hover,.elementor-kit-11 .elementor-button:focus{background-color:var( --e-global-color-accent );color:var( --e-global-color-c03bcf0 );}
    .elementor-kit-11 h2{color:var( --e-global-color-primary );font-family:var( --e-global-typography-edfb792-font-family ), Sans-serif;font-size:var( --e-global-typography-edfb792-font-size );font-weight:var( --e-global-typography-edfb792-font-weight );line-height:var( --e-global-typography-edfb792-line-height );}
    .elementor-kit-11 h3{color:var( --e-global-color-primary );font-size:var( --e-global-typography-b2c0f34-font-size );font-weight:var( --e-global-typography-b2c0f34-font-weight );line-height:var( --e-global-typography-b2c0f34-line-height );}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:1140px;}
    .elementor-widget:not(:last-child){margin-block-end:20px;}
    .elementor-element{--widgets-spacing:20px 20px;}
    @media (max-width:1024px){
    .elementor-kit-11 h2{font-size:var( --e-global-typography-edfb792-font-size );line-height:var( --e-global-typography-edfb792-line-height );}
    .elementor-kit-11 h3{font-size:var( --e-global-typography-b2c0f34-font-size );line-height:var( --e-global-typography-b2c0f34-line-height );}
    .elementor-kit-11 button,.elementor-kit-11 .elementor-button{font-size:var( --e-global-typography-1e647e3-font-size );line-height:var( --e-global-typography-1e647e3-line-height );padding:20px 30px 20px 30px;}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:1024px;}
    }
    @media (max-width:767px){
    .elementor-kit-11 h2{font-size:var( --e-global-typography-edfb792-font-size );line-height:var( --e-global-typography-edfb792-line-height );}
    .elementor-kit-11 h3{font-size:var( --e-global-typography-b2c0f34-font-size );line-height:var( --e-global-typography-b2c0f34-line-height );}
    .elementor-kit-11 button,.elementor-kit-11 .elementor-button{font-size:var( --e-global-typography-1e647e3-font-size );line-height:var( --e-global-typography-1e647e3-line-height );}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:767px;}
    }
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/widget-icon-list.min.css?ver=3.24.6 ; media=all */
    @media all{
    .elementor-widget .elementor-icon-list-items{list-style-type:none;margin:0;padding:0;}
    .elementor-widget .elementor-icon-list-item{margin:0;padding:0;position:relative;}
    .elementor-widget .elementor-icon-list-item:after{position:absolute;bottom:0;width:100%;}
    .elementor-widget .elementor-icon-list-item{display:flex;font-size:inherit;align-items:var(--icon-vertical-align,center);}
    .elementor-widget .elementor-icon-list-icon+.elementor-icon-list-text{align-self:center;padding-inline-start:5px;}
    .elementor-widget .elementor-icon-list-icon{display:flex;position:relative;top:var(--icon-vertical-offset,initial);}
    .elementor-widget .elementor-icon-list-icon i{width:1.25em;font-size:var(--e-icon-list-icon-size);}
    .elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon{text-align:var(--e-icon-list-icon-align);}
    .elementor-widget:not(.elementor-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-align-left) .elementor-icon-list-item:after{right:0;}
    /* @media (min-width:-1){
    .elementor-widget:not(.elementor-widescreen-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-widescreen-align-left) .elementor-icon-list-item:after{right:0;}
    }
    @media (max-width:-1){
    .elementor-widget:not(.elementor-laptop-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-laptop-align-left) .elementor-icon-list-item:after{right:0;}
    }
    @media (max-width:-1){
    .elementor-widget:not(.elementor-tablet_extra-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-tablet_extra-align-left) .elementor-icon-list-item:after{right:0;}
    }
    @media (max-width:1024px){
    .elementor-widget:not(.elementor-tablet-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-tablet-align-left) .elementor-icon-list-item:after{right:0;}
    }
    @media (max-width:-1){
    .elementor-widget:not(.elementor-mobile_extra-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-mobile_extra-align-left) .elementor-icon-list-item:after{right:0;}
    } */
    @media (max-width:767px){
    .elementor-widget.elementor-mobile-align-left .elementor-icon-list-item{justify-content:flex-start;text-align:left;}
    .elementor-widget:not(.elementor-mobile-align-right) .elementor-icon-list-item:after{left:0;}
    }
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/conditionals/e-swiper.min.css?ver=3.24.6 ; media=all */
    @media all{
    .elementor-element{--swiper-theme-color:#000;--swiper-navigation-size:44px;--swiper-pagination-bullet-size:6px;--swiper-pagination-bullet-horizontal-gap:6px;}
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/pro-elements/assets/css/widget-forms.min.css?ver=3.24.4 ; media=all */
    @media all{
    .e-form__buttons{flex-wrap:wrap;}
    .e-form__buttons{display:flex;}
    .elementor-form .elementor-button .elementor-button-content-wrapper{align-items:center;}
    .elementor-form .elementor-button .elementor-button-text{white-space:normal;}
    .elementor-form .elementor-button .elementor-button-content-wrapper{gap:5px;}
    .elementor-form .elementor-button .elementor-button-text{flex-grow:unset;order:unset;}
    .elementor-select-wrapper .select-caret-down-wrapper{font-size:11px;inset-inline-end:10px;pointer-events:none;position:absolute;top:50%;transform:translateY(-50%);}
    .elementor-select-wrapper .select-caret-down-wrapper svg{aspect-ratio:unset;display:unset;width:1em;fill:currentColor;}
    .elementor-select-wrapper.remove-before:before{content:""!important;}
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/uploads/elementor/css/post-238.css?ver=1731799501 ; media=all */
    @media all{
    .elementor-238 .elementor-element.elementor-element-4cd2316 > .elementor-background-overlay{background-image:url("https://uktravelroute.co.uk/wp-content/uploads/2024/09/design_element_Tree.png");background-position:96% 100%;background-repeat:no-repeat;opacity:0;}
    .elementor-238 .elementor-element.elementor-element-4cd2316{margin-top:0px;margin-bottom:0px;padding:100px 0px 100px 0px;}
    .elementor-238 .elementor-element.elementor-element-fe2c6ac > .elementor-element-populated{margin:0px 50px 0px 0px;--e-column-margin-right:50px;--e-column-margin-left:0px;}
    .elementor-238 .elementor-element.elementor-element-67a83de > .elementor-widget-container{margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;}
    .elementor-238 .elementor-element.elementor-element-67a83de .elementor-heading-title{color:var( --e-global-color-accent );font-size:17px;font-weight:bold;line-height:1.1em;}
    .elementor-238 .elementor-element.elementor-element-120d9d1 > .elementor-widget-container{margin:7px 0px 0px 0px;padding:0px 0px 0px 0px;}
    .elementor-238 .elementor-element.elementor-element-120d9d1{text-align:left;}
    .elementor-238 .elementor-element.elementor-element-120d9d1 .elementor-heading-title{font-family:"Jost", Sans-serif;font-size:35px;}
    .elementor-238 .elementor-element.elementor-element-4ef331c > .elementor-widget-container{margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;}
    .elementor-238 .elementor-element.elementor-element-4ef331c .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child){padding-bottom:calc(18px/2);}
    .elementor-238 .elementor-element.elementor-element-4ef331c .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child){margin-top:calc(18px/2);}
    .elementor-238 .elementor-element.elementor-element-4ef331c .elementor-icon-list-icon i{color:var( --e-global-color-accent );transition:color 0.3s;}
    .elementor-238 .elementor-element.elementor-element-4ef331c{--e-icon-list-icon-size:25px;--icon-vertical-offset:0px;}
    .elementor-238 .elementor-element.elementor-element-4ef331c .elementor-icon-list-icon{padding-right:5px;}
    .elementor-238 .elementor-element.elementor-element-4ef331c .elementor-icon-list-item > .elementor-icon-list-text{font-family:var( --e-global-typography-405dcf0-font-family ), Sans-serif;font-size:var( --e-global-typography-405dcf0-font-size );font-weight:var( --e-global-typography-405dcf0-font-weight );line-height:var( --e-global-typography-405dcf0-line-height );}
    .elementor-238 .elementor-element.elementor-element-4ef331c .elementor-icon-list-text{color:var( --e-global-color-primary );transition:color 0.3s;}
    .elementor-238 .elementor-element.elementor-element-f5c6f55:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap{background-color:var( --e-global-color-428be43 );}
    .elementor-238 .elementor-element.elementor-element-f5c6f55 > .elementor-element-populated{border-radius:10px 10px 10px 10px;}
    .elementor-238 .elementor-element.elementor-element-f5c6f55 > .elementor-element-populated{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:0px 0px 0px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;padding:45px 45px 45px 45px;}
    .elementor-238 .elementor-element.elementor-element-e9df722{text-align:center;}
    .elementor-238 .elementor-element.elementor-element-e9df722 .elementor-heading-title{font-size:35px;}
    .elementor-238 .elementor-element.elementor-element-2a2aed7 .elementor-field-group{padding-right:calc( 15px/2 );padding-left:calc( 15px/2 );margin-bottom:15px;}
    .elementor-238 .elementor-element.elementor-element-2a2aed7 .elementor-form-fields-wrapper{margin-left:calc( -15px/2 );margin-right:calc( -15px/2 );margin-bottom:-15px;}
    .elementor-238 .elementor-element.elementor-element-2a2aed7 .elementor-field-group:not(.elementor-field-type-upload) .elementor-field:not(.elementor-select-wrapper){background-color:#ffffff;border-radius:12px 12px 12px 12px;}
    .elementor-238 .elementor-element.elementor-element-2a2aed7 .elementor-field-group .elementor-select-wrapper select{background-color:#ffffff;border-radius:12px 12px 12px 12px;}
    .elementor-238 .elementor-element.elementor-element-2a2aed7 .elementor-button[type="submit"]{color:#ffffff;}
    .elementor-238 .elementor-element.elementor-element-2a2aed7 .elementor-button[type="submit"]:hover{color:#ffffff;}
    .elementor-238 .elementor-element.elementor-element-2a2aed7 .elementor-button{border-radius:12px 12px 12px 12px;padding:12px 12px 12px 12px;}
    .elementor-238 .elementor-element.elementor-element-2a2aed7{--e-form-steps-indicators-spacing:20px;--e-form-steps-indicator-padding:30px;--e-form-steps-indicator-inactive-secondary-color:#ffffff;--e-form-steps-indicator-active-secondary-color:#ffffff;--e-form-steps-indicator-completed-secondary-color:#ffffff;--e-form-steps-divider-width:1px;--e-form-steps-divider-gap:10px;}
    @media (max-width:1024px){
    .elementor-238 .elementor-element.elementor-element-4cd2316 > .elementor-background-overlay{background-position:0vw 0%;background-size:1px auto;}
    .elementor-238 .elementor-element.elementor-element-4cd2316{padding:80px 15px 80px 15px;}
    .elementor-238 .elementor-element.elementor-element-fe2c6ac > .elementor-element-populated{margin:0px 0px 30px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;}
    .elementor-238 .elementor-element.elementor-element-67a83de .elementor-heading-title{font-size:20px;line-height:1.1em;}
    .elementor-238 .elementor-element.elementor-element-4ef331c .elementor-icon-list-item > .elementor-icon-list-text{font-size:var( --e-global-typography-405dcf0-font-size );line-height:var( --e-global-typography-405dcf0-line-height );}
    .elementor-238 .elementor-element.elementor-element-e9df722 > .elementor-widget-container{margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;}
    }
    @media (max-width:767px){
    .elementor-238 .elementor-element.elementor-element-4cd2316 > .elementor-background-overlay{background-position:0vw 0%;}
    .elementor-238 .elementor-element.elementor-element-4cd2316{padding:50px 15px 50px 15px;}
    .elementor-238 .elementor-element.elementor-element-fe2c6ac > .elementor-element-populated{margin:0px 0px 30px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;}
    .elementor-238 .elementor-element.elementor-element-67a83de{text-align:left;}
    .elementor-238 .elementor-element.elementor-element-67a83de .elementor-heading-title{font-size:16px;line-height:1.1em;}
    .elementor-238 .elementor-element.elementor-element-120d9d1 > .elementor-widget-container{margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;}
    .elementor-238 .elementor-element.elementor-element-120d9d1{text-align:left;}
    .elementor-238 .elementor-element.elementor-element-4ef331c .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child){padding-bottom:calc(18px/2);}
    .elementor-238 .elementor-element.elementor-element-4ef331c .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child){margin-top:calc(18px/2);}
    .elementor-238 .elementor-element.elementor-element-4ef331c{--e-icon-list-icon-size:16px;}
    .elementor-238 .elementor-element.elementor-element-4ef331c .elementor-icon-list-item > .elementor-icon-list-text{font-size:var( --e-global-typography-405dcf0-font-size );line-height:var( --e-global-typography-405dcf0-line-height );}
    .elementor-238 .elementor-element.elementor-element-f5c6f55 > .elementor-element-populated{padding:20px 20px 20px 20px;}
    .elementor-238 .elementor-element.elementor-element-e9df722 .elementor-heading-title{font-size:28px;}
    }
    @media (min-width:768px){
    .elementor-238 .elementor-element.elementor-element-fe2c6ac{width:40%;}
    .elementor-238 .elementor-element.elementor-element-f5c6f55{width:60%;}
    }
    @media (max-width:1024px) and (min-width:768px){
    .elementor-238 .elementor-element.elementor-element-fe2c6ac{width:100%;}
    .elementor-238 .elementor-element.elementor-element-f5c6f55{width:100%;}
    }
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementskit-lite/modules/elementskit-icon-pack/assets/css/ekiticons.css?ver=3.2.8 ; media=all */
    @media all{
    .icon,.icon::before{font-family:elementskit!important;speak:none;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
    .icon.icon-map-marker::before{content:"\e833";}
    .icon.icon-phone1::before{content:"\e976";}
    .icon.icon-envelope2::before{content:"\ea13";}
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementskit-lite/widgets/init/assets/css/widget-styles.css?ver=3.2.8 ; media=all */
    @media all{
    :focus{outline:0;}
    }
    /*! CSS Used fontfaces */
    @font-face{font-family:'Jost';font-style:italic;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:elementskit;src:url(https://uktravelroute.co.uk/wp-content/plugins/elementskit-lite/modules/elementskit-icon-pack/assets/fonts/elementskit.woff?y24e1e) format("woff");font-weight:400;font-style:normal;font-display:swap;}