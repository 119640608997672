
@media all{
    *,:after,:before{box-sizing:border-box;}
    h2,h3{margin-block-start:.5rem;margin-block-end:1rem;font-family:inherit;font-weight:500;line-height:1.2;color:inherit;}
    h2{font-size:2rem;}
    h3{font-size:1.75rem;}
    p{margin-block-start:0;margin-block-end:.9rem;}
    img{border-style:none;height:auto;max-width:100%;}
    @media print{
    *,:after,:before{background:transparent!important;color:#000!important;box-shadow:none!important;text-shadow:none!important;}
    img{-moz-column-break-inside:avoid;break-inside:avoid;}
    h2,h3,p{orphans:3;widows:3;}
    h2,h3{-moz-column-break-after:avoid;break-after:avoid;}
    }
    }

    @media all{
    .elementor *,.elementor :after,.elementor :before{box-sizing:border-box;}
    .elementor img{height:auto;max-width:100%;border:none;border-radius:0;box-shadow:none;}
    .elementor .elementor-background-overlay{height:100%;width:100%;top:0;left:0;position:absolute;}
    .elementor-widget-wrap>.elementor-element.elementor-absolute{position:absolute;}
    .elementor-widget-wrap .elementor-element.elementor-widget__width-auto{max-width:100%;}
    .elementor-element{--flex-direction:initial;--flex-wrap:initial;--justify-content:initial;--align-items:initial;--align-content:initial;--gap:initial;--flex-basis:initial;--flex-grow:initial;--flex-shrink:initial;--order:initial;--align-self:initial;flex-basis:var(--flex-basis);flex-grow:var(--flex-grow);flex-shrink:var(--flex-shrink);order:var(--order);align-self:var(--align-self);}
    .elementor-element.elementor-absolute{z-index:1;}
    .elementor-element:where(.e-con-full,.elementor-widget){flex-direction:var(--flex-direction);flex-wrap:var(--flex-wrap);justify-content:var(--justify-content);align-items:var(--align-items);align-content:var(--align-content);gap:var(--gap);}
    .elementor-section{position:relative;}
    .elementor-section .elementor-container{display:flex;margin-right:auto;margin-left:auto;position:relative;}
    @media (max-width:1024px){
    .elementor-section .elementor-container{flex-wrap:wrap;}
    }
    .elementor-section.elementor-section-boxed>.elementor-container{max-width:1140px;}
    .elementor-widget-wrap{position:relative;width:100%;flex-wrap:wrap;align-content:flex-start;}
    .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap{display:flex;}
    .elementor-widget-wrap>.elementor-element{width:100%;}
    .elementor-widget{position:relative;}
    .elementor-widget:not(:last-child){margin-bottom:20px;}
    .elementor-column{position:relative;min-height:1px;display:flex;}
    .elementor-column-gap-default>.elementor-column>.elementor-element-populated{padding:10px;}
    @media (min-width:768px){
    .elementor-column.elementor-col-50{width:50%;}
    }
    @media (max-width:767px){
    .elementor-column{width:100%;}
    }
    .elementor-element .elementor-widget-container{transition:background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);}
    .elementor-heading-title{padding:0;margin:0;line-height:1;}
    @media (min-width:768px) and (max-width:1024px){
    .elementor .elementor-hidden-tablet{display:none;}
    }
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/uploads/elementor/css/post-11.css?ver=1731781304 ; media=all */
    @media all{
    .elementor-kit-11 h2{color:var( --e-global-color-primary );font-family:var( --e-global-typography-edfb792-font-family ), Sans-serif;font-size:var( --e-global-typography-edfb792-font-size );font-weight:var( --e-global-typography-edfb792-font-weight );line-height:var( --e-global-typography-edfb792-line-height );}
    .elementor-kit-11 h3{color:var( --e-global-color-primary );font-size:var( --e-global-typography-b2c0f34-font-size );font-weight:var( --e-global-typography-b2c0f34-font-weight );line-height:var( --e-global-typography-b2c0f34-line-height );}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:1140px;}
    .elementor-widget:not(:last-child){margin-block-end:20px;}
    .elementor-element{--widgets-spacing:20px 20px;}
    @media (max-width:1024px){
    .elementor-kit-11 h2{font-size:var( --e-global-typography-edfb792-font-size );line-height:var( --e-global-typography-edfb792-line-height );}
    .elementor-kit-11 h3{font-size:var( --e-global-typography-b2c0f34-font-size );line-height:var( --e-global-typography-b2c0f34-line-height );}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:1024px;}
    }
    @media (max-width:767px){
    .elementor-kit-11 h2{font-size:var( --e-global-typography-edfb792-font-size );line-height:var( --e-global-typography-edfb792-line-height );}
    .elementor-kit-11 h3{font-size:var( --e-global-typography-b2c0f34-font-size );line-height:var( --e-global-typography-b2c0f34-line-height );}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:767px;}
    }
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/widget-image.min.css?ver=3.24.6 ; media=all */
    @media all{
    .elementor-widget-image{text-align:center;}
    .elementor-widget-image img{vertical-align:middle;display:inline-block;}
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/lib/animations/styles/e-animation-float.min.css?ver=3.24.6 ; media=all */
    @media all{
    .elementor-animation-float{transition-duration:.3s;transition-property:transform;transition-timing-function:ease-out;}
    .elementor-animation-float:active,.elementor-animation-float:focus,.elementor-animation-float:hover{transform:translateY(-8px);}
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/conditionals/e-swiper.min.css?ver=3.24.6 ; media=all */
    @media all{
    .elementor-element{--swiper-theme-color:#000;--swiper-navigation-size:44px;--swiper-pagination-bullet-size:6px;--swiper-pagination-bullet-horizontal-gap:6px;}
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/uploads/elementor/css/post-230.css?ver=1731802824 ; media=all */
    @media all{
    .elementor-230 .elementor-element.elementor-element-b042a32 > .elementor-background-overlay{background-image:url("https://uktravelroute.co.uk/wp-content/uploads/2024/09/design_element_Tree.png");background-position:96% 100%;background-repeat:no-repeat;opacity:0;}
    .elementor-230 .elementor-element.elementor-element-b042a32{margin-top:0px;margin-bottom:0px;padding:100px 0px 100px 0px;}
    .elementor-230 .elementor-element.elementor-element-dd094e5.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-230 .elementor-element.elementor-element-05863ad > .elementor-widget-container{margin:1px 0px 0px 0px;padding:0px 0px 0px 0px;}
    .elementor-230 .elementor-element.elementor-element-05863ad .elementor-heading-title{color:var( --e-global-color-accent );font-size:var( --e-global-typography-b2c0f34-font-size );font-weight:var( --e-global-typography-b2c0f34-font-weight );line-height:var( --e-global-typography-b2c0f34-line-height );}
    .elementor-230 .elementor-element.elementor-element-9cb3a88 > .elementor-widget-container{margin:0px 0px 0px 0px;padding:6px 0px 3px 0px;}
    .elementor-230 .elementor-element.elementor-element-9cb3a88{text-align:left;}
    .elementor-230 .elementor-element.elementor-element-9cb3a88 .elementor-heading-title{font-family:var( --e-global-typography-primary-font-family ), Sans-serif;}
    .elementor-230 .elementor-element.elementor-element-dc01d88 > .elementor-widget-container{margin:0px 0px 0px 0px;padding:0px 0px 20px 0px;}
    .elementor-230 .elementor-element.elementor-element-dc01d88{color:var( --e-global-color-text );font-family:"Jost", Sans-serif;font-size:18px;font-weight:400;line-height:1.5em;}
    .elementor-230 .elementor-element.elementor-element-e59cae7 > .elementor-element-populated{margin:0px 0px 0px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;padding:0px 0px 0px 0px;}
    .elementor-230 .elementor-element.elementor-element-4047b02 > .elementor-widget-container{margin:0px 0px 0px 0px;padding:5px 0px 0px 0px;}
    .elementor-230 .elementor-element.elementor-element-4047b02{text-align:right;}
    .elementor-230 .elementor-element.elementor-element-4047b02 img{width:445px;height:530px;object-fit:cover;object-position:center center;border-style:solid;border-width:10px 10px 10px 10px;border-color:var( --e-global-color-c03bcf0 );border-radius:20px 20px 20px 20px;box-shadow:0px 10px 50px 0px rgba(29.999999999999975, 193.99999999999997, 138.99999999999997, 0.19);}
    .elementor-230 .elementor-element.elementor-element-b14db82{width:auto;max-width:auto;top:310px;text-align:right;}
    .elementor-230 .elementor-element.elementor-element-b14db82 > .elementor-widget-container{margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;}
    body:not(.rtl) .elementor-230 .elementor-element.elementor-element-b14db82{left:34px;}
    .elementor-230 .elementor-element.elementor-element-b14db82 img{width:296px;max-width:100%;height:275px;object-fit:cover;object-position:center center;border-style:solid;border-width:10px 10px 10px 10px;border-color:var( --e-global-color-c03bcf0 );border-radius:20px 20px 20px 20px;}
    @media (min-width:768px){
    .elementor-230 .elementor-element.elementor-element-dd094e5{width:51.667%;}
    .elementor-230 .elementor-element.elementor-element-e59cae7{width:48.333%;}
    }
    @media (max-width:1024px){
    .elementor-230 .elementor-element.elementor-element-b042a32 > .elementor-background-overlay{background-position:0vw 0%;background-size:1px auto;}
    .elementor-230 .elementor-element.elementor-element-b042a32{padding:80px 15px 50px 15px;}
    .elementor-230 .elementor-element.elementor-element-dd094e5 > .elementor-element-populated{margin:0px 0px 0px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;padding:0px 0px 0px 15px;}
    .elementor-230 .elementor-element.elementor-element-05863ad .elementor-heading-title{font-size:var( --e-global-typography-b2c0f34-font-size );line-height:var( --e-global-typography-b2c0f34-line-height );}
    .elementor-230 .elementor-element.elementor-element-dc01d88 > .elementor-widget-container{margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;}
    .elementor-230 .elementor-element.elementor-element-dc01d88{font-size:16px;line-height:1.1em;}
    .elementor-230 .elementor-element.elementor-element-e59cae7 > .elementor-element-populated{margin:0px 0px 0px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;padding:0px 0px 0px 0px;}
    .elementor-230 .elementor-element.elementor-element-4047b02 > .elementor-widget-container{margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;}
    .elementor-230 .elementor-element.elementor-element-4047b02 img{width:85%;border-width:5px 5px 5px 5px;border-radius:10px 10px 10px 10px;}
    body:not(.rtl) .elementor-230 .elementor-element.elementor-element-b14db82{left:133px;}
    .elementor-230 .elementor-element.elementor-element-b14db82{top:322px;text-align:center;}
    .elementor-230 .elementor-element.elementor-element-b14db82 img{width:90%;max-width:85%;border-width:5px 5px 5px 5px;border-radius:10px 10px 10px 10px;}
    }
    @media (max-width:767px){
    .elementor-230 .elementor-element.elementor-element-b042a32 > .elementor-background-overlay{background-position:0vw 0%;}
    .elementor-230 .elementor-element.elementor-element-b042a32{padding:50px 15px 50px 15px;}
    .elementor-230 .elementor-element.elementor-element-dd094e5 > .elementor-element-populated{margin:20px 0px 0px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;padding:0px 0px 0px 0px;}
    .elementor-230 .elementor-element.elementor-element-05863ad{text-align:center;}
    .elementor-230 .elementor-element.elementor-element-05863ad .elementor-heading-title{font-size:var( --e-global-typography-b2c0f34-font-size );line-height:var( --e-global-typography-b2c0f34-line-height );}
    .elementor-230 .elementor-element.elementor-element-9cb3a88{text-align:center;}
    .elementor-230 .elementor-element.elementor-element-dc01d88{text-align:center;font-size:16px;line-height:1.3em;}
    .elementor-230 .elementor-element.elementor-element-4047b02 img{width:100%;height:379px;}
    body:not(.rtl) .elementor-230 .elementor-element.elementor-element-b14db82{left:133px;}
    .elementor-230 .elementor-element.elementor-element-b14db82{top:260px;}
    .elementor-230 .elementor-element.elementor-element-b14db82 img{width:100%;max-width:100%;height:154px;}
    }
    }
    /*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementskit-lite/widgets/init/assets/css/widget-styles.css?ver=3.2.8 ; media=all */
    @media all{
    :focus{outline:0;}
    }
    /*! CSS Used fontfaces */
    @font-face{font-family:'Jost';font-style:italic;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:italic;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;}
    @font-face{font-family:'Jost';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}