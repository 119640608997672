@media all {
  *,
  :after,
  :before {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h2,
  h3,
  h6 {
    margin-block-start: 0.5rem;
    margin-block-end: 1rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h6 {
    font-size: 1rem;
  }
  p {
    margin-block-start: 0;
    margin-block-end: 0.9rem;
  }
  a {
    background-color: transparent;
    text-decoration: none;
    color: #c36;
  }
  a:active,
  a:hover {
    color: #336;
  }
  img {
    border-style: none;
    height: auto;
    max-width: 100%;
  }
  @media print {
    *,
    :after,
    :before {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }
    a,
    a:visited {
      text-decoration: underline;
    }
    a[href]:after {
      content: " (" attr(href) ")";
    }
    a[href^="#"]:after {
      content: "";
    }
    img {
      -moz-column-break-inside: avoid;
      break-inside: avoid;
    }
    h2,
    h3,
    p {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      -moz-column-break-after: avoid;
      break-after: avoid;
    }
  }
  button {
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0;
  }
  button {
    overflow: visible;
  }
  button {
    text-transform: none;
  }
  [type="button"],
  button {
    width: auto;
    -webkit-appearance: button;
  }
  [type="button"],
  button {
    display: inline-block;
    font-weight: 400;
    color: #c36;
    text-align: center;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid #c36;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 3px;
    transition: all 0.3s;
  }
  [type="button"]:focus:not(:focus-visible),
  button:focus:not(:focus-visible) {
    outline: none;
  }
  [type="button"]:focus,
  [type="button"]:hover,
  button:focus,
  button:hover {
    color: #fff;
    background-color: #c36;
    text-decoration: none;
  }
  [type="button"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }
  li,
  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/themes/hello-elementor/theme.min.css?ver=3.1.1 ; media=all */
@media all {
  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
  }
  .screen-reader-text:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #333;
    display: block;
    font-size: 1rem;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 12px 24px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-screen-only,
  .screen-reader-text {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .elementor *,
  .elementor :after,
  .elementor :before {
    box-sizing: border-box;
  }
  .elementor a {
    box-shadow: none;
    text-decoration: none;
  }
  .elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor):not(
      .elementor-widget-theme-post-content
    )
    figure {
    margin: 0;
  }
  .elementor .elementor-background-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  .elementor-widget-wrap > .elementor-element.elementor-absolute {
    position: absolute;
  }
  .elementor-widget-wrap .elementor-element.elementor-widget__width-auto {
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    .elementor-widget-wrap
      .elementor-element.elementor-widget-tablet__width-auto {
      max-width: 100%;
    }
  }
  .elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self);
  }
  .elementor-element.elementor-absolute {
    z-index: 1;
  }
  .elementor-element:where(.e-con-full, .elementor-widget) {
    flex-direction: var(--flex-direction);
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    gap: var(--gap);
  }
  .elementor-align-center {
    text-align: center;
  }
  .elementor-align-center .elementor-button {
    width: auto;
  }
  .elementor-align-right {
    text-align: right;
  }
  .elementor-align-left {
    text-align: left;
  }
  .elementor-align-justify .elementor-button {
    width: 100%;
  }
  @media (max-width: 767px) {
    .elementor-mobile-align-center {
      text-align: center;
    }
  }
  .elementor-section {
    position: relative;
  }
  .elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }
  @media (max-width: 1024px) {
    
    .elementor-section .elementor-container {
      flex-wrap: wrap;
    }
  }
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
  }
  .elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex;
  }
  .elementor-widget-wrap > .elementor-element {
    width: 100%;
  }
  .elementor-widget {
    position: relative;
  }
  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }
  .elementor-widget:not(:last-child).elementor-absolute,
  .elementor-widget:not(:last-child).elementor-widget__width-auto {
    margin-bottom: 0;
  }
  .elementor-column {
    position: relative;
    min-height: 1px;
    display: flex;
  }
  .elementor-column-gap-default
    > .elementor-column
    > .elementor-element-populated {
    padding: 10px;
  }
  .elementor-inner-section
    .elementor-column-gap-no
    .elementor-element-populated {
    padding: 0;
  }
  @media (min-width: 768px) {
    .elementor-column.elementor-col-33 {
      width: 33.333%;
    }
    .elementor-column.elementor-col-50 {
      width: 50%;
    }
    .elementor-column.elementor-col-100 {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .elementor-column {
      width: 100%;
    }
  }
  .elementor-grid {
    display: grid;
    grid-column-gap: var(--grid-column-gap);
    grid-row-gap: var(--grid-row-gap);
  }
  .elementor-grid .elementor-grid-item {
    min-width: 0;
  }
  .elementor-grid-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap));
  }
  .elementor-grid-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
  }
  @media (min-width: 1025px) {
    #elementor-device-mode:after {
      content: "desktop";
    }
  }

  @media (max-width: 1024px) {
    #elementor-device-mode:after {
      content: "tablet";
    }
  }

  @media (max-width: 767px) {
    #elementor-device-mode:after {
      content: "mobile";
    }
  }
  .elementor-element .elementor-widget-container {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s, transform var(--e-transform-transition-duration, 0.4s);
  }
  .elementor-heading-title {
    padding: 0;
    margin: 0;
    line-height: 1;
  }
  .elementor-button {
    display: inline-block;
    line-height: 1;
    background-color: #69727d;
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color: #fff;
    fill: #fff;
    text-align: center;
    transition: all 0.3s;
  }
  .elementor-button:focus,
  .elementor-button:hover,
  .elementor-button:visited {
    color: #fff;
  }
  .elementor-button-content-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
  }
  .elementor-button-icon svg {
    width: 1em;
    height: auto;
  }
  .elementor-button-icon .e-font-icon-svg {
    height: 1em;
  }
  .elementor-button-text {
    display: inline-block;
  }
  .elementor-button span {
    text-decoration: inherit;
  }
  .elementor-view-stacked .elementor-icon {
    padding: 0.5em;
    background-color: #69727d;
    color: #fff;
    fill: #fff;
  }
  .elementor-icon {
    display: inline-block;
    line-height: 1;
    transition: all 0.3s;
    color: #69727d;
    font-size: 50px;
    text-align: center;
  }
  .elementor-icon:hover {
    color: #69727d;
  }
  .elementor-icon i,
  .elementor-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
  }
  .elementor-icon i:before,
  .elementor-icon svg:before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .elementor-shape-circle .elementor-icon {
    border-radius: 50%;
  }
  .animated {
    animation-duration: 1.25s;
  }
  @media (prefers-reduced-motion: reduce) {
    .animated {
      animation: none;
    }
  }
  .elementor .elementor-element ul.elementor-icon-list-items {
    padding: 0;
  }
  @media (max-width: 767px) {
    .elementor .elementor-hidden-mobile {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .elementor .elementor-hidden-tablet {
      display: none;
    }
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/uploads/elementor/css/post-11.css?ver=1728936492 ; media=all */
@media all {
  .elementor-kit-11 {
    --e-global-color-primary: #1d2c47;
    --e-global-color-secondary: #f7e000;
    --e-global-color-text: #73848c;
    --e-global-color-accent: #2462d1;
    --e-global-color-c03bcf0: #ffffff;
    --e-global-color-428be43: #b7d1ff;
    --e-global-color-372b13c: #606480;
    --e-global-typography-primary-font-family: "Jost";
    --e-global-typography-secondary-font-family: "Jost";
    --e-global-typography-text-font-family: "Jost";
    --e-global-typography-accent-font-family: "Jost";
    --e-global-typography-405dcf0-font-family: "Jost";
    --e-global-typography-405dcf0-font-size: 18px;
    --e-global-typography-405dcf0-font-weight: 500;
    --e-global-typography-5d5fd86-font-family: "Jost";
    --e-global-typography-5d5fd86-font-size: 75px;
    --e-global-typography-5d5fd86-font-weight: 600;
    --e-global-typography-5d5fd86-line-height: 1.1em;
    --e-global-typography-edfb792-font-family: "Jost";
    --e-global-typography-edfb792-font-size: 60px;
    --e-global-typography-edfb792-font-weight: bold;
    --e-global-typography-edfb792-line-height: 1.1em;
    --e-global-typography-b2c0f34-font-size: 24px;
    --e-global-typography-b2c0f34-font-weight: bold;
    --e-global-typography-b2c0f34-line-height: 1.1em;
    --e-global-typography-b756bfd-font-family: "Jost";
    --e-global-typography-b756bfd-font-size: 18px;
    --e-global-typography-b756bfd-font-weight: 400;
    --e-global-typography-b756bfd-line-height: 1.5em;
    --e-global-typography-1e647e3-font-family: "Jost";
    --e-global-typography-1e647e3-font-size: 18px;
    --e-global-typography-1e647e3-font-weight: 600;
    --e-global-typography-1e647e3-line-height: 1.5em;
    --e-global-typography-ef912e8-font-family: "Jost";
    --e-global-typography-ef912e8-font-size: 16px;
    --e-global-typography-ef912e8-font-weight: 400;
    --e-global-typography-ef912e8-line-height: 1.5em;
    --e-global-typography-0fdd03b-font-family: "Jost";
    --e-global-typography-0fdd03b-font-size: 20px;
    --e-global-typography-0fdd03b-font-weight: 400;
    --e-global-typography-0fdd03b-line-height: 1.5em;
    --e-global-typography-8439560-font-family: "Jost";
    --e-global-typography-8439560-font-size: 18px;
    --e-global-typography-8439560-font-weight: 500;
    --e-global-typography-8439560-line-height: 1.1px;
    --e-global-typography-51a5abd-font-family: "Jost";
    --e-global-typography-51a5abd-font-size: 14px;
    --e-global-typography-51a5abd-font-weight: 400;
    --e-global-typography-51a5abd-line-height: 1.1em;
    --e-global-typography-a297d6e-font-family: "Jost";
    --e-global-typography-a297d6e-font-size: 40px;
    --e-global-typography-a297d6e-font-weight: bold;
    --e-global-typography-a297d6e-text-decoration: none;
    --e-global-typography-a297d6e-line-height: 1.1em;
    --e-global-typography-44900e9-font-family: "Jost";
    --e-global-typography-44900e9-font-size: 18px;
    --e-global-typography-44900e9-font-weight: 600;
    --e-global-typography-44900e9-text-decoration: none;
    --e-global-typography-3de61a3-font-family: "Jost";
    --e-global-typography-3de61a3-font-size: 14px;
    --e-global-typography-3de61a3-font-weight: 400;
    --e-global-typography-437bfb9-font-family: "Jost";
    --e-global-typography-437bfb9-font-size: 16px;
    --e-global-typography-437bfb9-font-weight: 400;
    --e-global-typography-437bfb9-line-height: 1.9em;
    --e-global-typography-126ffe0-font-family: "Jost";
    --e-global-typography-126ffe0-font-size: 16px;
    --e-global-typography-126ffe0-font-weight: 500;
    --e-global-typography-126ffe0-text-decoration: none;
    --e-global-typography-126ffe0-letter-spacing: 0px;
    --e-global-typography-9e513e2-font-family: "Jost";
    --e-global-typography-9e513e2-font-size: 18px;
    --e-global-typography-9e513e2-font-weight: 500;
    --e-global-typography-9e513e2-font-style: normal;
    --e-global-typography-9e513e2-text-decoration: none;
    --e-global-typography-9e513e2-line-height: 1.1em;
    --e-global-typography-7db0c2f-font-family: "Jost";
    --e-global-typography-7db0c2f-font-size: 20px;
    --e-global-typography-7db0c2f-font-weight: 400;
    --e-global-typography-7db0c2f-text-decoration: none;
    --e-global-typography-1a77ca0-font-family: "Jost";
    --e-global-typography-1a77ca0-font-size: 20px;
    --e-global-typography-1a77ca0-font-weight: 400;
    --e-global-typography-1a77ca0-text-decoration: none;
    --e-global-typography-1a77ca0-line-height: 1.2em;
    color: var(--e-global-color-text);
    font-family: var(--e-global-typography-text-font-family), Sans-serif;
  }
  .elementor-kit-11 button,
  .elementor-kit-11 .elementor-button {
    background-color: var(--e-global-color-accent);
    font-family: var(--e-global-typography-1e647e3-font-family), Sans-serif;
    font-size: var(--e-global-typography-1e647e3-font-size);
    font-weight: var(--e-global-typography-1e647e3-font-weight);
    line-height: var(--e-global-typography-1e647e3-line-height);
    color: var(--e-global-color-c03bcf0);
    box-shadow: 0px 0px 50px 0px
      rgba(29.999999999999986, 194.00000000000006, 139.00000000000003, 0.2);
    border-radius: 50px 50px 50px 50px;
    padding: 27px 47px 27px 47px;
  }
  .elementor-kit-11 button:hover,
  .elementor-kit-11 button:focus,
  .elementor-kit-11 .elementor-button:hover,
  .elementor-kit-11 .elementor-button:focus {
    background-color: var(--e-global-color-accent);
    color: var(--e-global-color-c03bcf0);
  }
  .elementor-kit-11 a {
    color: var(--e-global-color-accent);
    font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  }
  .elementor-kit-11 a:hover {
    color: var(--e-global-color-secondary);
    font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  }
  .elementor-kit-11 h2 {
    color: var(--e-global-color-primary);
    font-family: var(--e-global-typography-edfb792-font-family), Sans-serif;
    font-size: var(--e-global-typography-edfb792-font-size);
    font-weight: var(--e-global-typography-edfb792-font-weight);
    line-height: var(--e-global-typography-edfb792-line-height);
  }
  .elementor-kit-11 h3 {
    color: var(--e-global-color-primary);
    font-size: var(--e-global-typography-b2c0f34-font-size);
    font-weight: var(--e-global-typography-b2c0f34-font-weight);
    line-height: var(--e-global-typography-b2c0f34-line-height);
  }
  .elementor-kit-11 h6 {
    color: var(--e-global-color-text);
    font-family: var(--e-global-typography-ef912e8-font-family), Sans-serif;
    font-size: var(--e-global-typography-ef912e8-font-size);
    font-weight: var(--e-global-typography-ef912e8-font-weight);
    line-height: var(--e-global-typography-ef912e8-line-height);
  }
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
  }
  .elementor-widget:not(:last-child) {
    margin-block-end: 20px;
  }
  .elementor-element {
    --widgets-spacing: 20px 20px;
  }
  @media (max-width: 1024px) {
    .elementor-kit-11 {
      --e-global-typography-405dcf0-font-size: 16px;
      --e-global-typography-405dcf0-line-height: 1.1em;
      --e-global-typography-5d5fd86-font-size: 50px;
      --e-global-typography-5d5fd86-line-height: 1.1em;
      --e-global-typography-edfb792-font-size: 40px;
      --e-global-typography-edfb792-line-height: 1.1em;
      --e-global-typography-b2c0f34-font-size: 20px;
      --e-global-typography-b2c0f34-line-height: 1.1em;
      --e-global-typography-b756bfd-font-size: 16px;
      --e-global-typography-b756bfd-line-height: 1.1em;
      --e-global-typography-1e647e3-font-size: 16px;
      --e-global-typography-1e647e3-line-height: 1.1em;
      --e-global-typography-ef912e8-font-size: 14px;
      --e-global-typography-ef912e8-line-height: 1.1em;
      --e-global-typography-0fdd03b-font-size: 18px;
      --e-global-typography-0fdd03b-line-height: 1.1em;
      --e-global-typography-8439560-font-size: 16px;
      --e-global-typography-8439560-line-height: 1.1em;
      --e-global-typography-51a5abd-font-size: 14px;
      --e-global-typography-51a5abd-line-height: 1.1em;
      --e-global-typography-a297d6e-font-size: 32px;
      --e-global-typography-a297d6e-line-height: 1.1em;
      --e-global-typography-44900e9-font-size: 16px;
      --e-global-typography-44900e9-line-height: 1.1em;
      --e-global-typography-3de61a3-font-size: 14px;
      --e-global-typography-3de61a3-line-height: 1.1em;
      --e-global-typography-437bfb9-font-size: 16px;
      --e-global-typography-437bfb9-line-height: 1.5em;
      --e-global-typography-126ffe0-font-size: 14px;
      --e-global-typography-126ffe0-line-height: 1.1em;
      --e-global-typography-7db0c2f-font-size: 18px;
      --e-global-typography-7db0c2f-line-height: 1.1em;
    }
    .elementor-kit-11 h2 {
      font-size: var(--e-global-typography-edfb792-font-size);
      line-height: var(--e-global-typography-edfb792-line-height);
    }
    .elementor-kit-11 h3 {
      font-size: var(--e-global-typography-b2c0f34-font-size);
      line-height: var(--e-global-typography-b2c0f34-line-height);
    }
    .elementor-kit-11 h6 {
      font-size: var(--e-global-typography-ef912e8-font-size);
      line-height: var(--e-global-typography-ef912e8-line-height);
    }
    .elementor-kit-11 button,
    .elementor-kit-11 .elementor-button {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
      padding: 20px 30px 20px 30px;
    }
    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 1024px;
    }
  }
  @media (max-width: 767px) {
    .elementor-kit-11 {
      --e-global-typography-405dcf0-font-size: 14px;
      --e-global-typography-405dcf0-line-height: 1.1em;
      --e-global-typography-5d5fd86-font-size: 28px;
      --e-global-typography-5d5fd86-line-height: 1.1em;
      --e-global-typography-edfb792-font-size: 24px;
      --e-global-typography-edfb792-line-height: 1.1em;
      --e-global-typography-b2c0f34-font-size: 16px;
      --e-global-typography-b2c0f34-line-height: 1.1em;
      --e-global-typography-b756bfd-font-size: 14px;
      --e-global-typography-b756bfd-line-height: 1.1em;
      --e-global-typography-1e647e3-font-size: 14px;
      --e-global-typography-1e647e3-line-height: 1.1em;
      --e-global-typography-ef912e8-font-size: 12px;
      --e-global-typography-ef912e8-line-height: 1.1em;
      --e-global-typography-0fdd03b-font-size: 16px;
      --e-global-typography-0fdd03b-line-height: 1.1em;
      --e-global-typography-8439560-font-size: 14px;
      --e-global-typography-8439560-line-height: 1.1em;
      --e-global-typography-51a5abd-font-size: 12px;
      --e-global-typography-51a5abd-line-height: 1.1em;
      --e-global-typography-a297d6e-font-size: 26px;
      --e-global-typography-a297d6e-line-height: 1.1em;
      --e-global-typography-44900e9-font-size: 14px;
      --e-global-typography-44900e9-line-height: 1.1em;
      --e-global-typography-3de61a3-font-size: 12px;
      --e-global-typography-3de61a3-line-height: 1.1em;
      --e-global-typography-437bfb9-font-size: 12px;
      --e-global-typography-437bfb9-line-height: 1.9em;
      --e-global-typography-126ffe0-font-size: 12px;
      --e-global-typography-126ffe0-line-height: 1.1em;
      --e-global-typography-7db0c2f-font-size: 16px;
      --e-global-typography-7db0c2f-line-height: 1.1em;
    }
    .elementor-kit-11 h2 {
      font-size: var(--e-global-typography-edfb792-font-size);
      line-height: var(--e-global-typography-edfb792-line-height);
    }
    .elementor-kit-11 h3 {
      font-size: var(--e-global-typography-b2c0f34-font-size);
      line-height: var(--e-global-typography-b2c0f34-line-height);
    }
    .elementor-kit-11 h6 {
      font-size: var(--e-global-typography-ef912e8-font-size);
      line-height: var(--e-global-typography-ef912e8-line-height);
    }
    .elementor-kit-11 button,
    .elementor-kit-11 .elementor-button {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 767px;
    }
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/widget-icon-list.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-widget .elementor-icon-list-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .elementor-widget .elementor-icon-list-item {
    margin: 0;
    padding: 0;
    position: relative;
  }
  .elementor-widget .elementor-icon-list-item:after {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .elementor-widget .elementor-icon-list-item,
  .elementor-widget .elementor-icon-list-item a {
    display: flex;
    font-size: inherit;
    align-items: var(--icon-vertical-align, center);
  }
  .elementor-widget .elementor-icon-list-icon + .elementor-icon-list-text {
    align-self: center;
    padding-inline-start: 5px;
  }
  .elementor-widget .elementor-icon-list-icon {
    display: flex;
    position: relative;
    top: var(--icon-vertical-offset, initial);
  }
  .elementor-widget .elementor-icon-list-icon svg {
    width: var(--e-icon-list-icon-size, 1em);
    height: var(--e-icon-list-icon-size, 1em);
  }
  .elementor-widget .elementor-icon-list-icon i {
    width: 1.25em;
    font-size: var(--e-icon-list-icon-size);
  }
  .elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon {
    text-align: var(--e-icon-list-icon-align);
  }
  .elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon svg {
    margin: var(
      --e-icon-list-icon-margin,
      0 calc(var(--e-icon-list-icon-size, 1em) * 0.25) 0 0
    );
  }
  .elementor-widget.elementor-list-item-link-full_width a {
    width: 100%;
  }
  .elementor-widget.elementor-align-left .elementor-icon-list-item,
  .elementor-widget.elementor-align-left .elementor-icon-list-item a {
    justify-content: flex-start;
    text-align: left;
  }
  .elementor-widget.elementor-align-right .elementor-icon-list-item,
  .elementor-widget.elementor-align-right .elementor-icon-list-item a {
    justify-content: flex-end;
    text-align: right;
  }
  .elementor-widget.elementor-align-right .elementor-icon-list-items {
    justify-content: flex-end;
  }
  .elementor-widget:not(.elementor-align-right)
    .elementor-icon-list-item:after {
    left: 0;
  }
  .elementor-widget:not(.elementor-align-left) .elementor-icon-list-item:after {
    right: 0;
  }
 
  @media (max-width: 1024px) {
    .elementor-widget:not(.elementor-tablet-align-right)
      .elementor-icon-list-item:after {
      left: 0;
    }
    .elementor-widget:not(.elementor-tablet-align-left)
      .elementor-icon-list-item:after {
      right: 0;
    }
  }
  
  @media (max-width: 767px) {
    .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item,
    .elementor-widget.elementor-mobile-align-center
      .elementor-icon-list-item
      a {
      justify-content: center;
    }
    .elementor-widget.elementor-mobile-align-center
      .elementor-icon-list-item:after {
      margin: auto;
    }
    .elementor-widget:not(.elementor-mobile-align-right)
      .elementor-icon-list-item:after {
      left: 0;
    }
    .elementor-widget:not(.elementor-mobile-align-left)
      .elementor-icon-list-item:after {
      right: 0;
    }
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/widget-image.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-widget-image {
    text-align: center;
  }
  .elementor-widget-image a {
    display: inline-block;
  }
  .elementor-widget-image img {
    vertical-align: middle;
    display: inline-block;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/lib/animations/styles/e-animation-float.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-animation-float {
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;
  }
  .elementor-animation-float:active,
  .elementor-animation-float:focus,
  .elementor-animation-float:hover {
    transform: translateY(-8px);
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/widget-divider.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-widget-divider {
    --divider-border-style: none;
    --divider-border-width: 1px;
    --divider-color: #0c0d0e;
    --divider-icon-size: 20px;
    --divider-element-spacing: 10px;
    --divider-pattern-height: 24px;
    --divider-pattern-size: 20px;
    --divider-pattern-url: none;
    --divider-pattern-repeat: repeat-x;
  }
  .elementor-widget-divider .elementor-divider {
    display: flex;
  }
  .elementor-widget-divider .elementor-divider-separator {
    display: flex;
    margin: 0;
    direction: ltr;
  }
  .elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(
      .elementor-widget-divider--view-line_icon
    )
    .elementor-divider-separator {
    border-block-start: var(--divider-border-width) var(--divider-border-style)
      var(--divider-color);
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/lib/animations/styles/e-animation-shrink.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-animation-shrink {
    transition-duration: 0.3s;
    transition-property: transform;
  }
  .elementor-animation-shrink:active,
  .elementor-animation-shrink:focus,
  .elementor-animation-shrink:hover {
    transform: scale(0.9);
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/widget-social-icons.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-widget-social-icons.elementor-grid-0 .elementor-widget-container {
    line-height: 1;
    font-size: 0;
  }
  .elementor-widget-social-icons .elementor-grid {
    grid-column-gap: var(--grid-column-gap, 5px);
    grid-row-gap: var(--grid-row-gap, 5px);
    grid-template-columns: var(--grid-template-columns);
    justify-content: var(--justify-content, center);
    justify-items: var(--justify-content, center);
  }
  .elementor-icon.elementor-social-icon {
    font-size: var(--icon-size, 25px);
    line-height: var(--icon-size, 25px);
    width: calc(var(--icon-size, 25px) + 2 * var(--icon-padding, 0.5em));
    height: calc(var(--icon-size, 25px) + 2 * var(--icon-padding, 0.5em));
  }
  .elementor-social-icon {
    --e-social-icon-icon-color: #fff;
    display: inline-flex;
    background-color: #69727d;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }
  .elementor-social-icon i {
    color: var(--e-social-icon-icon-color);
  }
  .elementor-social-icon svg {
    fill: var(--e-social-icon-icon-color);
  }
  .elementor-social-icon:last-child {
    margin: 0;
  }
  .elementor-social-icon:hover {
    opacity: 0.9;
    color: #fff;
  }
  .elementor-social-icon-linkedin-in {
    background-color: #0077b5;
  }
  .elementor-social-icon-pinterest {
    background-color: #bd081c;
  }
  .elementor-social-icon-x-twitter {
    background-color: #000;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/widget-spacer.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-column .elementor-spacer-inner {
    height: var(--spacer-size);
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/conditionals/e-swiper.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-element {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/pro-elements/assets/css/frontend.min.css?ver=3.24.4 ; media=all */
@media all {
  .elementor-location-footer:before,
  .elementor-location-header:before {
    clear: both;
    content: "";
    display: table;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/lib/animations/styles/fadeInLeft.min.css?ver=3.24.6 ; media=all */
@media all {
  .fadeInLeft {
    animation-name: fadeInLeft;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/widget-icon-box.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-widget-icon-box .elementor-icon-box-wrapper {
    display: block;
    text-align: center;
  }
  .elementor-widget-icon-box .elementor-icon-box-icon {
    margin-bottom: var(--icon-box-icon-margin, 15px);
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 768px) {
    .elementor-widget-icon-box.elementor-vertical-align-middle
      .elementor-icon-box-wrapper {
      align-items: center;
    }
  }
  .elementor-widget-icon-box.elementor-position-left
    .elementor-icon-box-wrapper {
    display: flex;
  }
  .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon {
    display: inline-flex;
    flex: 0 0 auto;
  }
  .elementor-widget-icon-box.elementor-position-left
    .elementor-icon-box-wrapper {
    text-align: start;
    flex-direction: row;
  }
  .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon {
    margin-right: var(--icon-box-icon-margin, 15px);
    margin-left: 0;
    margin-bottom: unset;
  }
  @media (max-width: 1024px) {
    .elementor-widget-icon-box.elementor-tablet-position-left
      .elementor-icon-box-wrapper {
      display: flex;
    }
    .elementor-widget-icon-box.elementor-tablet-position-left
      .elementor-icon-box-icon {
      display: inline-flex;
      flex: 0 0 auto;
    }
    .elementor-widget-icon-box.elementor-tablet-position-left
      .elementor-icon-box-wrapper {
      text-align: start;
      flex-direction: row;
    }
    .elementor-widget-icon-box.elementor-tablet-position-left
      .elementor-icon-box-icon {
      margin-right: var(--icon-box-icon-margin, 15px);
      margin-left: 0;
      margin-bottom: unset;
    }
  }
  @media (max-width: 767px) {
    .elementor-widget-icon-box.elementor-mobile-position-left
      .elementor-icon-box-wrapper,
    .elementor-widget-icon-box.elementor-mobile-position-right
      .elementor-icon-box-wrapper {
      display: flex;
    }
    .elementor-widget-icon-box.elementor-mobile-position-left
      .elementor-icon-box-icon,
    .elementor-widget-icon-box.elementor-mobile-position-right
      .elementor-icon-box-icon {
      display: inline-flex;
      flex: 0 0 auto;
    }
    .elementor-widget-icon-box.elementor-mobile-position-right
      .elementor-icon-box-wrapper {
      text-align: end;
      flex-direction: row-reverse;
    }
    .elementor-widget-icon-box.elementor-mobile-position-right
      .elementor-icon-box-icon {
      margin-left: var(--icon-box-icon-margin, 15px);
      margin-right: 0;
      margin-bottom: unset;
    }
    .elementor-widget-icon-box.elementor-mobile-position-left
      .elementor-icon-box-wrapper {
      text-align: start;
      flex-direction: row;
    }
    .elementor-widget-icon-box.elementor-mobile-position-left
      .elementor-icon-box-icon {
      margin-right: var(--icon-box-icon-margin, 15px);
      margin-left: 0;
      margin-bottom: unset;
    }
    .elementor-widget-icon-box.elementor-position-left
      .elementor-icon-box-icon {
      display: block;
      flex: unset;
    }
  }
  .elementor-widget-icon-box .elementor-icon-box-title a {
    color: inherit;
  }
  .elementor-widget-icon-box .elementor-icon-box-content {
    flex-grow: 1;
  }
  .elementor-widget-icon-box .elementor-icon-box-description {
    margin: 0;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/pro-elements/assets/css/widget-animated-headline.min.css?ver=3.24.4 ; media=all */
@media all {
  .elementor-headline {
    display: block;
    font-size: 43px;
    line-height: 1.2;
  }
  .elementor-headline-dynamic-text {
    color: var(--dynamic-text-color, #aebcb9);
  }
  .elementor-headline--style-rotate .elementor-headline-dynamic-wrapper {
    text-align: start;
  }
  .elementor-headline-text-wrapper {
    vertical-align: bottom;
  }
  .elementor-headline-dynamic-wrapper {
    display: inline-block;
    position: relative;
  }
  .elementor-headline-dynamic-wrapper .elementor-headline-dynamic-text {
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
  }
  .elementor-headline-dynamic-wrapper
    .elementor-headline-dynamic-text.elementor-headline-text-active {
    position: relative;
  }
  .elementor-headline-animation-type-drop-in
    .elementor-headline-dynamic-wrapper {
    perspective: 300px;
  }
  .elementor-headline-animation-type-drop-in .elementor-headline-dynamic-text {
    opacity: 0;
  }
  .elementor-headline-animation-type-drop-in
    .elementor-headline-dynamic-text.elementor-headline-text-active {
    animation: elementor-headline-drop-in-in 0.8s;
    opacity: 1;
  }
  .elementor-headline-animation-type-drop-in
    .elementor-headline-dynamic-text.elementor-headline-text-inactive {
    animation: elementor-headline-drop-in-out 0.8s;
  }
  .elementor-headline-animation-type-drop-in
    .elementor-headline-dynamic-wrapper {
    transition: width 0.5s;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/lib/animations/styles/fadeInUp.min.css?ver=3.24.6 ; media=all */
@media all {
  .fadeInUp {
    animation-name: fadeInUp;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/css/widget-image-box.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-widget-image-box .elementor-image-box-content {
    width: 100%;
  }
  @media (min-width: 768px) {
    .elementor-widget-image-box.elementor-position-top
      .elementor-image-box-img {
      margin: auto;
    }
  }
  @media (max-width: 767px) {
    .elementor-widget-image-box .elementor-image-box-img {
      margin-left: auto !important;
      margin-right: auto !important;
      margin-bottom: 15px;
    }
  }
  .elementor-widget-image-box .elementor-image-box-img {
    display: inline-block;
  }
  .elementor-widget-image-box .elementor-image-box-title a {
    color: inherit;
  }
  .elementor-widget-image-box .elementor-image-box-wrapper {
    text-align: center;
  }
  .elementor-widget-image-box .elementor-image-box-description {
    margin: 0;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementor/assets/lib/animations/styles/e-animation-bob.min.css?ver=3.24.6 ; media=all */
@media all {
  .elementor-animation-bob:active,
  .elementor-animation-bob:focus,
  .elementor-animation-bob:hover {
    animation-name: elementor-animation-bob-float, elementor-animation-bob;
    animation-duration: 0.3s, 1.5s;
    animation-delay: 0s, 0.3s;
    animation-timing-function: ease-out, ease-in-out;
    animation-iteration-count: 1, infinite;
    animation-fill-mode: forwards;
    animation-direction: normal, alternate;
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/uploads/elementor/css/post-107.css?ver=1728936493 ; media=all */
@media all {
  .elementor-107
    .elementor-element.elementor-element-8a5cded:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 0%,
      var(--e-global-color-c03bcf0) 100%
    );
  }
  .elementor-107
    .elementor-element.elementor-element-8a5cded
    > .elementor-background-overlay {
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }
  .elementor-107 .elementor-element.elementor-element-8a5cded {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 170px 0px 80px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-7e4b527.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-7e4b527
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-095da5f {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-1fc58cd.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-1fc58cd
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-107 .elementor-element.elementor-element-3b6e185 {
    width: auto;
    max-width: auto;
    top: -169px;
  }
  body:not(.rtl) .elementor-107 .elementor-element.elementor-element-3b6e185 {
    left: 210px;
  }
  .elementor-107 .elementor-element.elementor-element-3b6e185 img {
    opacity: 1;
  }
  .elementor-107
    .elementor-element.elementor-element-96c6cb6
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-96c6cb6 {
    --icon-box-icon-margin: 9px;
  }
  .elementor-107
    .elementor-element.elementor-element-96c6cb6
    .elementor-icon-box-title {
    margin-bottom: 8px;
    color: var(--e-global-color-accent);
  }
  .elementor-107
    .elementor-element.elementor-element-96c6cb6.elementor-view-default
    .elementor-icon {
    fill: var(--e-global-color-accent);
    color: var(--e-global-color-accent);
    border-color: var(--e-global-color-accent);
  }
  .elementor-107
    .elementor-element.elementor-element-96c6cb6.elementor-view-default
    .elementor-icon:hover {
    fill: var(--e-global-color-text);
    color: var(--e-global-color-text);
    border-color: var(--e-global-color-text);
  }
  .elementor-107 .elementor-element.elementor-element-96c6cb6 .elementor-icon {
    font-size: 20px;
  }
  .elementor-107 .elementor-element.elementor-element-6c5b724 {
    --iteration-count: infinite;
    --dynamic-text-color: var(--e-global-color-primary);
  }
  .elementor-107
    .elementor-element.elementor-element-6c5b724
    .elementor-headline {
    text-align: left;
    font-size: 70px;
  }
  .elementor-107
    .elementor-element.elementor-element-6c5b724
    .elementor-headline-plain-text {
    color: var(--e-global-color-primary);
  }
  .elementor-107
    .elementor-element.elementor-element-e6d2072
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 6px 0px 18px 16px;
  }
  .elementor-107 .elementor-element.elementor-element-e6d2072 {
    text-align: left;
    font-family: "Jost", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5em;
  }
  .elementor-107 .elementor-element.elementor-element-39f21ae {
    width: auto;
    max-width: auto;
    top: 528px;
    text-align: left;
  }
  body:not(.rtl) .elementor-107 .elementor-element.elementor-element-39f21ae {
    left: -210px;
  }
  .elementor-107 .elementor-element.elementor-element-39f21ae img {
    opacity: 1;
  }
  .elementor-107 .elementor-element.elementor-element-0859072 {
    width: auto;
    max-width: auto;
    top: -49px;
  }
  body:not(.rtl) .elementor-107 .elementor-element.elementor-element-0859072 {
    left: 484px;
  }
  .elementor-107 .elementor-element.elementor-element-aa58f4d {
    margin-top: 80px;
    margin-bottom: 0px;
  }
  .elementor-107 .elementor-element.elementor-element-9ad998a {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 70px 0px 40px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-2d8aa13
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 50px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-1b9a9b5
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 7px 16px;
  }
  .elementor-107 .elementor-element.elementor-element-1b9a9b5 {
    text-align: center;
  }
  .elementor-107
    .elementor-element.elementor-element-1b9a9b5
    .elementor-heading-title {
    color: var(--e-global-color-secondary);
    font-size: var(--e-global-typography-b2c0f34-font-size);
    font-weight: var(--e-global-typography-b2c0f34-font-weight);
    line-height: var(--e-global-typography-b2c0f34-line-height);
  }
  .elementor-107 .elementor-element.elementor-element-7ca5669 {
    text-align: center;
  }
  .elementor-107
    .elementor-element.elementor-element-7ca5669
    .elementor-heading-title {
    font-family: "Jost", Sans-serif;
    font-size: 60px;
    font-weight: bold;
    line-height: 1.1em;
  }
  .elementor-107
    .elementor-element.elementor-element-43129fb
    > .elementor-container {
    max-width: 940px;
  }
  .elementor-107 .elementor-element.elementor-element-43129fb {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 6px 80px 27px 80px;
  }
  .elementor-107
    .elementor-element.elementor-element-d94d17c
    > .elementor-element-populated {
    margin: 0px 13px 0px 10px;
    --e-column-margin-right: 13px;
    --e-column-margin-left: 10px;
  }
  .elementor-107
    .elementor-element.elementor-element-9766f41
    > .elementor-widget-container {
    background-color: transparent;
    margin: 0px 0px 0px 0px;
    padding: 50px 22px 40px 22px;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 50%,
      #ffffff61 100%
    );
    transition: background 0.2s;
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-107
    .elementor-element.elementor-element-9766f41:hover
    .elementor-widget-container {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 0%,
      var(--e-global-color-c03bcf0) 100%
    );
  }
  .elementor-107
    .elementor-element.elementor-element-9766f41.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 15px;
  }
  .elementor-107
    .elementor-element.elementor-element-9766f41
    .elementor-image-box-title {
    margin-bottom: 20px;
    font-family: "Jost", Sans-serif;
  }
  .elementor-107
    .elementor-element.elementor-element-9766f41
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 30%;
  }
  .elementor-107
    .elementor-element.elementor-element-9766f41
    .elementor-image-box-img
    img {
    transition-duration: 0.3s;
  }
  .elementor-107
    .elementor-element.elementor-element-9766f41
    .elementor-image-box-description {
    font-family: "Jost", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
  }
  .elementor-107
    .elementor-element.elementor-element-095a268
    > .elementor-element-populated {
    margin: 30px 13px 40px 13px;
    --e-column-margin-right: 13px;
    --e-column-margin-left: 13px;
  }
  .elementor-107
    .elementor-element.elementor-element-aacfa86
    > .elementor-widget-container {
    background-color: transparent;
    margin: 0px 0px 0px 0px;
    padding: 50px 22px 40px 22px;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 50%,
      #ffffff61 100%
    );
    transition: background 0.2s;
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-107
    .elementor-element.elementor-element-aacfa86:hover
    .elementor-widget-container {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 0%,
      var(--e-global-color-c03bcf0) 100%
    );
  }
  .elementor-107
    .elementor-element.elementor-element-aacfa86.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 15px;
  }
  .elementor-107
    .elementor-element.elementor-element-aacfa86
    .elementor-image-box-title {
    margin-bottom: 20px;
    font-family: "Jost", Sans-serif;
  }
  .elementor-107
    .elementor-element.elementor-element-aacfa86
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 30%;
  }
  .elementor-107
    .elementor-element.elementor-element-aacfa86
    .elementor-image-box-img
    img {
    transition-duration: 0.3s;
  }
  .elementor-107
    .elementor-element.elementor-element-aacfa86
    .elementor-image-box-description {
    font-family: "Jost", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
  }
  .elementor-107
    .elementor-element.elementor-element-8a1b7c5
    > .elementor-element-populated {
    margin: 70px 10px 0px 13px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 13px;
  }
  .elementor-107
    .elementor-element.elementor-element-c58621c
    > .elementor-widget-container {
    background-color: transparent;
    margin: 0px 0px 0px 0px;
    padding: 50px 22px 40px 22px;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 50%,
      #ffffff61 100%
    );
    transition: background 0.2s;
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-107
    .elementor-element.elementor-element-c58621c:hover
    .elementor-widget-container {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 0%,
      var(--e-global-color-c03bcf0) 100%
    );
  }
  .elementor-107
    .elementor-element.elementor-element-c58621c.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 15px;
  }
  .elementor-107
    .elementor-element.elementor-element-c58621c
    .elementor-image-box-title {
    margin-bottom: 20px;
    font-family: "Jost", Sans-serif;
  }
  .elementor-107
    .elementor-element.elementor-element-c58621c
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 30%;
  }
  .elementor-107
    .elementor-element.elementor-element-c58621c
    .elementor-image-box-img
    img {
    transition-duration: 0.3s;
  }
  .elementor-107
    .elementor-element.elementor-element-c58621c
    .elementor-image-box-description {
    font-family: "Jost", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
  }
  .elementor-107
    .elementor-element.elementor-element-bac71f5:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-image: url("https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec2_bacground.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .elementor-107
    .elementor-element.elementor-element-bac71f5
    > .elementor-background-overlay {
    background-color: var(--e-global-color-accent);
    opacity: 0.5;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }
  .elementor-107 .elementor-element.elementor-element-bac71f5 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 100px 0px 100px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-f97ba5d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 7px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-f97ba5d {
    text-align: center;
  }
  .elementor-107
    .elementor-element.elementor-element-f97ba5d
    .elementor-heading-title {
    color: var(--e-global-color-secondary);
    font-size: var(--e-global-typography-b2c0f34-font-size);
    font-weight: var(--e-global-typography-b2c0f34-font-weight);
    line-height: var(--e-global-typography-b2c0f34-line-height);
  }
  .elementor-107
    .elementor-element.elementor-element-84d937f
    > .elementor-widget-container {
    margin: 0px 0px 30px 0px;
    padding: 0px 178px 0px 180px;
  }
  .elementor-107 .elementor-element.elementor-element-84d937f {
    text-align: center;
  }
  .elementor-107
    .elementor-element.elementor-element-84d937f
    .elementor-heading-title {
    color: var(--e-global-color-c03bcf0);
  }
  .elementor-107 .elementor-element.elementor-element-4b4ed06 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-b9a8bce:not(
      .elementor-motion-effects-element-type-background
    )
    > .elementor-widget-wrap {
    background-color: var(--e-global-color-c03bcf0);
  }
  .elementor-107
    .elementor-element.elementor-element-b9a8bce:hover
    > .elementor-element-populated {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 0%,
      var(--e-global-color-c03bcf0) 50%
    );
  }
  .elementor-107
    .elementor-element.elementor-element-b9a8bce.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-b9a8bce
    > .elementor-element-populated
    > .elementor-background-overlay {
    opacity: 1;
  }
  .elementor-107
    .elementor-element.elementor-element-b9a8bce:hover
    > .elementor-element-populated
    > .elementor-background-overlay {
    opacity: 1;
  }
  .elementor-107
    .elementor-element.elementor-element-b9a8bce
    > .elementor-element-populated,
  .elementor-107
    .elementor-element.elementor-element-b9a8bce
    > .elementor-element-populated
    > .elementor-background-overlay {
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-107
    .elementor-element.elementor-element-b9a8bce
    > .elementor-element-populated {
    transition: background 0s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 15px 0px 14px;
    --e-column-margin-right: 15px;
    --e-column-margin-left: 14px;
    padding: 18px 0px 18px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-b9a8bce
    > .elementor-element-populated
    > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }
  .elementor-107
    .elementor-element.elementor-element-409a356
    > .elementor-widget-container {
    margin: 0px 0px -4px 0px;
    padding: 0px 0px 0px 20px;
  }
  .elementor-107
    .elementor-element.elementor-element-409a356
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-107 .elementor-element.elementor-element-409a356 {
    --icon-box-icon-margin: 9px;
  }
  .elementor-107
    .elementor-element.elementor-element-409a356
    .elementor-icon-box-title {
    margin-bottom: 6px;
  }
  .elementor-107
    .elementor-element.elementor-element-409a356.elementor-view-stacked
    .elementor-icon {
    background-color: #ff890026;
    fill: var(--e-global-color-secondary);
    color: var(--e-global-color-secondary);
  }
  .elementor-107
    .elementor-element.elementor-element-409a356.elementor-view-stacked
    .elementor-icon:hover {
    background-color: var(--e-global-color-c03bcf0);
    fill: var(--e-global-color-text);
    color: var(--e-global-color-text);
  }
  .elementor-107 .elementor-element.elementor-element-409a356 .elementor-icon {
    font-size: 18px;
    border-radius: 50px 50px 50px 50px;
  }
  .elementor-107
    .elementor-element.elementor-element-1bed2fc
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 3px 20px;
  }
  .elementor-107 .elementor-element.elementor-element-1bed2fc {
    text-align: left;
  }
  .elementor-107
    .elementor-element.elementor-element-1bed2fc
    .elementor-heading-title {
    color: var(--e-global-color-primary);
    font-family: var(--e-global-typography-1e647e3-font-family), Sans-serif;
    font-size: var(--e-global-typography-1e647e3-font-size);
    font-weight: var(--e-global-typography-1e647e3-font-weight);
    line-height: var(--e-global-typography-1e647e3-line-height);
  }
  .elementor-107
    .elementor-element.elementor-element-93f4af9
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-93f4af9 {
    text-align: center;
  }
  .elementor-107 .elementor-element.elementor-element-cf0b9b4 {
    --divider-border-style: solid;
    --divider-color: #2b294521;
    --divider-border-width: 2px;
  }
  .elementor-107
    .elementor-element.elementor-element-cf0b9b4
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-cf0b9b4
    .elementor-divider-separator {
    width: 88%;
    margin: 0 auto;
    margin-center: 0;
  }
  .elementor-107
    .elementor-element.elementor-element-cf0b9b4
    .elementor-divider {
    text-align: center;
    padding-block-start: 2px;
    padding-block-end: 2px;
  }
  .elementor-107 .elementor-element.elementor-element-6d6edcb {
    margin-top: -1px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-331ce52.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-331ce52
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 21px;
  }
  .elementor-107 .elementor-element.elementor-element-8d8881a {
    width: auto;
    max-width: auto;
    text-align: center;
  }
  .elementor-107
    .elementor-element.elementor-element-8d8881a
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-a3f5743 {
    width: auto;
    max-width: auto;
    align-self: center;
  }
  .elementor-107
    .elementor-element.elementor-element-a3f5743
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 4px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-216e761.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-216e761
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 14px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-d1d60ea
    .elementor-button {
    background-color: var(--e-global-color-text);
    font-family: var(--e-global-typography-ef912e8-font-family), Sans-serif;
    font-size: var(--e-global-typography-ef912e8-font-size);
    font-weight: var(--e-global-typography-ef912e8-font-weight);
    line-height: var(--e-global-typography-ef912e8-line-height);
    fill: var(--e-global-color-c03bcf0);
    color: var(--e-global-color-c03bcf0);
    border-radius: 35px 35px 35px 35px;
    box-shadow: 0px 10px 50px 0px
      rgba(29.999999999999975, 193.99999999999997, 138.99999999999997, 0.21);
    padding: 13px 21px 13px 20px;
  }
  .elementor-107
    .elementor-element.elementor-element-d1d60ea
    .elementor-button:hover,
  .elementor-107
    .elementor-element.elementor-element-d1d60ea
    .elementor-button:focus {
    background-color: var(--e-global-color-accent);
    color: var(--e-global-color-c03bcf0);
  }
  .elementor-107
    .elementor-element.elementor-element-d1d60ea
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-c2f26c6:not(
      .elementor-motion-effects-element-type-background
    )
    > .elementor-widget-wrap {
    background-color: var(--e-global-color-c03bcf0);
  }
  .elementor-107
    .elementor-element.elementor-element-c2f26c6:hover
    > .elementor-element-populated {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 0%,
      var(--e-global-color-c03bcf0) 50%
    );
  }
  .elementor-107
    .elementor-element.elementor-element-c2f26c6.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-c2f26c6
    > .elementor-element-populated
    > .elementor-background-overlay {
    opacity: 1;
  }
  .elementor-107
    .elementor-element.elementor-element-c2f26c6
    > .elementor-element-populated,
  .elementor-107
    .elementor-element.elementor-element-c2f26c6
    > .elementor-element-populated
    > .elementor-background-overlay {
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-107
    .elementor-element.elementor-element-c2f26c6
    > .elementor-element-populated {
    transition: background 0s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 15px 0px 15px;
    --e-column-margin-right: 15px;
    --e-column-margin-left: 15px;
    padding: 18px 0px 18px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-c2f26c6
    > .elementor-element-populated
    > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }
  .elementor-107
    .elementor-element.elementor-element-cc2f5fb
    > .elementor-widget-container {
    margin: 0px 0px -4px 0px;
    padding: 0px 0px 0px 20px;
  }
  .elementor-107
    .elementor-element.elementor-element-cc2f5fb
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-107 .elementor-element.elementor-element-cc2f5fb {
    --icon-box-icon-margin: 9px;
  }
  .elementor-107
    .elementor-element.elementor-element-cc2f5fb
    .elementor-icon-box-title {
    margin-bottom: 6px;
  }
  .elementor-107
    .elementor-element.elementor-element-cc2f5fb.elementor-view-stacked
    .elementor-icon {
    background-color: #ff890026;
    fill: var(--e-global-color-secondary);
    color: var(--e-global-color-secondary);
  }
  .elementor-107
    .elementor-element.elementor-element-cc2f5fb.elementor-view-stacked
    .elementor-icon:hover {
    background-color: var(--e-global-color-c03bcf0);
    fill: var(--e-global-color-text);
    color: var(--e-global-color-text);
  }
  .elementor-107 .elementor-element.elementor-element-cc2f5fb .elementor-icon {
    font-size: 18px;
    border-radius: 50px 50px 50px 50px;
  }
  .elementor-107
    .elementor-element.elementor-element-61b7a6d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 3px 20px;
  }
  .elementor-107 .elementor-element.elementor-element-61b7a6d {
    text-align: left;
  }
  .elementor-107
    .elementor-element.elementor-element-61b7a6d
    .elementor-heading-title {
    color: var(--e-global-color-primary);
    font-family: var(--e-global-typography-1e647e3-font-family), Sans-serif;
    font-size: var(--e-global-typography-1e647e3-font-size);
    font-weight: var(--e-global-typography-1e647e3-font-weight);
    line-height: var(--e-global-typography-1e647e3-line-height);
  }
  .elementor-107
    .elementor-element.elementor-element-c68d10d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-c68d10d {
    text-align: center;
  }
  .elementor-107 .elementor-element.elementor-element-275a9f0 {
    --divider-border-style: solid;
    --divider-color: #2b294521;
    --divider-border-width: 2px;
  }
  .elementor-107
    .elementor-element.elementor-element-275a9f0
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-275a9f0
    .elementor-divider-separator {
    width: 88%;
    margin: 0 auto;
    margin-center: 0;
  }
  .elementor-107
    .elementor-element.elementor-element-275a9f0
    .elementor-divider {
    text-align: center;
    padding-block-start: 2px;
    padding-block-end: 2px;
  }
  .elementor-107 .elementor-element.elementor-element-5e25983 {
    margin-top: -1px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-111b16d.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-111b16d
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 21px;
  }
  .elementor-107 .elementor-element.elementor-element-933d9fb {
    width: auto;
    max-width: auto;
    text-align: center;
  }
  .elementor-107
    .elementor-element.elementor-element-933d9fb
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-7ed077f {
    width: auto;
    max-width: auto;
    align-self: center;
  }
  .elementor-107
    .elementor-element.elementor-element-7ed077f
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 4px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-67d9331.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-67d9331
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 14px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-07d6603
    .elementor-button {
    background-color: var(--e-global-color-text);
    font-family: var(--e-global-typography-ef912e8-font-family), Sans-serif;
    font-size: var(--e-global-typography-ef912e8-font-size);
    font-weight: var(--e-global-typography-ef912e8-font-weight);
    line-height: var(--e-global-typography-ef912e8-line-height);
    fill: var(--e-global-color-c03bcf0);
    color: var(--e-global-color-c03bcf0);
    border-radius: 35px 35px 35px 35px;
    box-shadow: 0px 10px 50px 0px
      rgba(29.999999999999975, 193.99999999999997, 138.99999999999997, 0.21);
    padding: 13px 20px 13px 20px;
  }
  .elementor-107
    .elementor-element.elementor-element-07d6603
    .elementor-button:hover,
  .elementor-107
    .elementor-element.elementor-element-07d6603
    .elementor-button:focus {
    background-color: var(--e-global-color-accent);
    color: var(--e-global-color-c03bcf0);
  }
  .elementor-107
    .elementor-element.elementor-element-07d6603
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-feb5213:not(
      .elementor-motion-effects-element-type-background
    )
    > .elementor-widget-wrap {
    background-color: var(--e-global-color-c03bcf0);
  }
  .elementor-107
    .elementor-element.elementor-element-feb5213:hover
    > .elementor-element-populated {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 0%,
      var(--e-global-color-c03bcf0) 50%
    );
  }
  .elementor-107
    .elementor-element.elementor-element-feb5213.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-feb5213
    > .elementor-element-populated
    > .elementor-background-overlay {
    opacity: 1;
  }
  .elementor-107
    .elementor-element.elementor-element-feb5213
    > .elementor-element-populated,
  .elementor-107
    .elementor-element.elementor-element-feb5213
    > .elementor-element-populated
    > .elementor-background-overlay {
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-107
    .elementor-element.elementor-element-feb5213
    > .elementor-element-populated {
    transition: background 0s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 15px 0px 15px;
    --e-column-margin-right: 15px;
    --e-column-margin-left: 15px;
    padding: 18px 0px 18px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-feb5213
    > .elementor-element-populated
    > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }
  .elementor-107
    .elementor-element.elementor-element-dd24504
    > .elementor-widget-container {
    margin: 0px 0px -4px 0px;
    padding: 0px 0px 0px 20px;
  }
  .elementor-107
    .elementor-element.elementor-element-dd24504
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-107 .elementor-element.elementor-element-dd24504 {
    --icon-box-icon-margin: 9px;
  }
  .elementor-107
    .elementor-element.elementor-element-dd24504
    .elementor-icon-box-title {
    margin-bottom: 6px;
  }
  .elementor-107
    .elementor-element.elementor-element-dd24504.elementor-view-stacked
    .elementor-icon {
    background-color: #ff890026;
    fill: var(--e-global-color-secondary);
    color: var(--e-global-color-secondary);
  }
  .elementor-107
    .elementor-element.elementor-element-dd24504.elementor-view-stacked
    .elementor-icon:hover {
    background-color: var(--e-global-color-c03bcf0);
    fill: var(--e-global-color-text);
    color: var(--e-global-color-text);
  }
  .elementor-107 .elementor-element.elementor-element-dd24504 .elementor-icon {
    font-size: 18px;
    border-radius: 50px 50px 50px 50px;
  }
  .elementor-107
    .elementor-element.elementor-element-c831e7d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 3px 20px;
  }
  .elementor-107 .elementor-element.elementor-element-c831e7d {
    text-align: left;
  }
  .elementor-107
    .elementor-element.elementor-element-c831e7d
    .elementor-heading-title {
    color: var(--e-global-color-primary);
    font-family: var(--e-global-typography-1e647e3-font-family), Sans-serif;
    font-size: var(--e-global-typography-1e647e3-font-size);
    font-weight: var(--e-global-typography-1e647e3-font-weight);
    line-height: var(--e-global-typography-1e647e3-line-height);
  }
  .elementor-107
    .elementor-element.elementor-element-55d2228
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-55d2228 {
    text-align: center;
  }
  .elementor-107 .elementor-element.elementor-element-8c65830 {
    --divider-border-style: solid;
    --divider-color: #2b294521;
    --divider-border-width: 2px;
  }
  .elementor-107
    .elementor-element.elementor-element-8c65830
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-8c65830
    .elementor-divider-separator {
    width: 88%;
    margin: 0 auto;
    margin-center: 0;
  }
  .elementor-107
    .elementor-element.elementor-element-8c65830
    .elementor-divider {
    text-align: center;
    padding-block-start: 2px;
    padding-block-end: 2px;
  }
  .elementor-107 .elementor-element.elementor-element-9f2a9d6 {
    margin-top: -1px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-114e580.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-114e580
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 21px;
  }
  .elementor-107 .elementor-element.elementor-element-3bdd496 {
    width: auto;
    max-width: auto;
    text-align: center;
  }
  .elementor-107
    .elementor-element.elementor-element-3bdd496
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-c164a3d {
    width: auto;
    max-width: auto;
    align-self: center;
  }
  .elementor-107
    .elementor-element.elementor-element-c164a3d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 4px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-4a879b6.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-4a879b6
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 14px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-a1ed705
    .elementor-button {
    background-color: var(--e-global-color-text);
    font-family: var(--e-global-typography-ef912e8-font-family), Sans-serif;
    font-size: var(--e-global-typography-ef912e8-font-size);
    font-weight: var(--e-global-typography-ef912e8-font-weight);
    line-height: var(--e-global-typography-ef912e8-line-height);
    fill: var(--e-global-color-c03bcf0);
    color: var(--e-global-color-c03bcf0);
    border-radius: 35px 35px 35px 35px;
    box-shadow: 0px 10px 50px 0px
      rgba(29.999999999999975, 193.99999999999997, 138.99999999999997, 0.21);
    padding: 13px 20px 13px 20px;
  }
  .elementor-107
    .elementor-element.elementor-element-a1ed705
    .elementor-button:hover,
  .elementor-107
    .elementor-element.elementor-element-a1ed705
    .elementor-button:focus {
    background-color: var(--e-global-color-accent);
    color: var(--e-global-color-c03bcf0);
  }
  .elementor-107
    .elementor-element.elementor-element-a1ed705
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-87d89bd:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 0%,
      var(--e-global-color-c03bcf0) 100%
    );
  }
  .elementor-107
    .elementor-element.elementor-element-87d89bd
    > .elementor-background-overlay {
    background-image: url("https://uktravelroute.co.uk/wp-content/uploads/2024/09/design_element_Tree.png");
    background-position: 96% 100%;
    background-repeat: no-repeat;
    opacity: 0;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }
  .elementor-107 .elementor-element.elementor-element-87d89bd {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 145px 0px 132px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-9a59b61
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-bdbed02
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 5px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-bdbed02 {
    text-align: left;
  }
  .elementor-107 .elementor-element.elementor-element-bdbed02 img {
    width: 445px;
    height: 530px;
    object-fit: cover;
    object-position: center center;
    border-style: solid;
    border-width: 10px 10px 10px 10px;
    border-color: var(--e-global-color-c03bcf0);
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0px 10px 50px 0px
      rgba(29.999999999999975, 193.99999999999997, 138.99999999999997, 0.19);
  }
  .elementor-107 .elementor-element.elementor-element-8a1e635 {
    width: auto;
    max-width: auto;
    top: 310px;
    text-align: right;
  }
  .elementor-107
    .elementor-element.elementor-element-8a1e635
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  body:not(.rtl) .elementor-107 .elementor-element.elementor-element-8a1e635 {
    left: 230px;
  }
  .elementor-107 .elementor-element.elementor-element-8a1e635 img {
    width: 296px;
    max-width: 100%;
    height: 275px;
    object-fit: cover;
    object-position: center center;
    border-style: solid;
    border-width: 10px 10px 10px 10px;
    border-color: var(--e-global-color-c03bcf0);
    border-radius: 20px 20px 20px 20px;
  }
  .elementor-107
    .elementor-element.elementor-element-3182c0c.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-107
    .elementor-element.elementor-element-322181b
    > .elementor-widget-container {
    margin: 1px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-322181b
    .elementor-heading-title {
    color: var(--e-global-color-accent);
    font-size: var(--e-global-typography-b2c0f34-font-size);
    font-weight: var(--e-global-typography-b2c0f34-font-weight);
    line-height: var(--e-global-typography-b2c0f34-line-height);
  }
  .elementor-107
    .elementor-element.elementor-element-6af5a4d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 6px 0px 3px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-6af5a4d {
    text-align: left;
  }
  .elementor-107
    .elementor-element.elementor-element-6af5a4d
    .elementor-heading-title {
    font-family: "Jost", Sans-serif;
  }
  .elementor-107
    .elementor-element.elementor-element-19b8ce8
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 20px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-19b8ce8 {
    color: var(--e-global-color-text);
    font-family: "Jost", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
  }
  .elementor-107
    .elementor-element.elementor-element-1dd8048
    > .elementor-background-overlay {
    background-image: url("https://uktravelroute.co.uk/wp-content/uploads/2024/09/design_element_sec2.png");
    background-position: 95% 78%;
    background-repeat: no-repeat;
    opacity: 0;
  }
  .elementor-107 .elementor-element.elementor-element-1dd8048 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 80px 0px 100px 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-4b93d44
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-107 .elementor-element.elementor-element-2a52f2f {
    width: auto;
    max-width: auto;
    top: -35px;
    text-align: left;
  }
  .elementor-107
    .elementor-element.elementor-element-2a52f2f
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  body:not(.rtl) .elementor-107 .elementor-element.elementor-element-2a52f2f {
    left: -290px;
  }
  .elementor-107
    .elementor-element.elementor-element-048826c
    > .elementor-widget-container {
    margin: 0px 0px 7px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-048826c {
    text-align: center;
  }
  .elementor-107
    .elementor-element.elementor-element-048826c
    .elementor-heading-title {
    color: var(--e-global-color-accent);
  }
  .elementor-107
    .elementor-element.elementor-element-fa0c29a
    > .elementor-widget-container {
    margin: 0px 12px 18px 0px;
    padding: 0px 190px 0px 180px;
  }
  .elementor-107 .elementor-element.elementor-element-fa0c29a {
    text-align: center;
  }
  .elementor-107 .elementor-element.elementor-element-aacba8a {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .elementor-107
    .elementor-element.elementor-element-54cbbc0
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-affffca img {
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: var(--e-global-color-c03bcf0);
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-107 .elementor-element.elementor-element-4645267 {
    width: auto;
    max-width: auto;
  }
  .elementor-107
    .elementor-element.elementor-element-4645267
    > .elementor-widget-container {
    margin: -135px 0px 0px 0px;
    padding: 0px 114px 0px 46px;
  }
  .elementor-107
    .elementor-element.elementor-element-4645267
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-107
    .elementor-element.elementor-element-4645267
    .elementor-icon-box-title {
    margin-bottom: 10px;
    color: var(--e-global-color-c03bcf0);
  }
  .elementor-107
    .elementor-element.elementor-element-4645267
    .elementor-icon-box-title {
    font-family: var(--e-global-typography-3de61a3-font-family), Sans-serif;
    font-size: var(--e-global-typography-3de61a3-font-size);
    font-weight: var(--e-global-typography-3de61a3-font-weight);
    line-height: var(--e-global-typography-3de61a3-line-height);
  }
  .elementor-107
    .elementor-element.elementor-element-4645267
    .elementor-icon-box-description {
    color: var(--e-global-color-c03bcf0);
    font-family: var(--e-global-typography-1e647e3-font-family), Sans-serif;
    font-size: var(--e-global-typography-1e647e3-font-size);
    font-weight: var(--e-global-typography-1e647e3-font-weight);
    line-height: var(--e-global-typography-1e647e3-line-height);
  }
  .elementor-107
    .elementor-element.elementor-element-bb1bb26
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-33f7c39 img {
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: var(--e-global-color-c03bcf0);
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-107 .elementor-element.elementor-element-fff12ce {
    width: auto;
    max-width: auto;
  }
  .elementor-107
    .elementor-element.elementor-element-fff12ce
    > .elementor-widget-container {
    margin: -135px 0px 0px 0px;
    padding: 0px 114px 0px 46px;
  }
  .elementor-107
    .elementor-element.elementor-element-fff12ce
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-107
    .elementor-element.elementor-element-fff12ce
    .elementor-icon-box-title {
    margin-bottom: 10px;
    color: var(--e-global-color-c03bcf0);
  }
  .elementor-107
    .elementor-element.elementor-element-fff12ce
    .elementor-icon-box-title {
    font-family: var(--e-global-typography-3de61a3-font-family), Sans-serif;
    font-size: var(--e-global-typography-3de61a3-font-size);
    font-weight: var(--e-global-typography-3de61a3-font-weight);
    line-height: var(--e-global-typography-3de61a3-line-height);
  }
  .elementor-107
    .elementor-element.elementor-element-fff12ce
    .elementor-icon-box-description {
    color: var(--e-global-color-c03bcf0);
    font-family: var(--e-global-typography-1e647e3-font-family), Sans-serif;
    font-size: var(--e-global-typography-1e647e3-font-size);
    font-weight: var(--e-global-typography-1e647e3-font-weight);
    line-height: var(--e-global-typography-1e647e3-line-height);
  }
  .elementor-107
    .elementor-element.elementor-element-684e0f4
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-107 .elementor-element.elementor-element-86982e2 img {
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: var(--e-global-color-c03bcf0);
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-107 .elementor-element.elementor-element-35895bb {
    width: auto;
    max-width: auto;
  }
  .elementor-107
    .elementor-element.elementor-element-35895bb
    > .elementor-widget-container {
    margin: -135px 0px 0px 0px;
    padding: 0px 114px 0px 46px;
  }
  .elementor-107
    .elementor-element.elementor-element-35895bb
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-107
    .elementor-element.elementor-element-35895bb
    .elementor-icon-box-title {
    margin-bottom: 10px;
    color: var(--e-global-color-c03bcf0);
  }
  .elementor-107
    .elementor-element.elementor-element-35895bb
    .elementor-icon-box-title {
    font-family: var(--e-global-typography-3de61a3-font-family), Sans-serif;
    font-size: var(--e-global-typography-3de61a3-font-size);
    font-weight: var(--e-global-typography-3de61a3-font-weight);
    line-height: var(--e-global-typography-3de61a3-line-height);
  }
  .elementor-107
    .elementor-element.elementor-element-35895bb
    .elementor-icon-box-description {
    color: var(--e-global-color-c03bcf0);
    font-family: var(--e-global-typography-1e647e3-font-family), Sans-serif;
    font-size: var(--e-global-typography-1e647e3-font-size);
    font-weight: var(--e-global-typography-1e647e3-font-weight);
    line-height: var(--e-global-typography-1e647e3-line-height);
  }
  @media (min-width: 768px) {
    .elementor-107 .elementor-element.elementor-element-1fc58cd {
      width: 47.893%;
    }
    .elementor-107 .elementor-element.elementor-element-a790e66 {
      width: 52.107%;
    }
    .elementor-107 .elementor-element.elementor-element-331ce52 {
      width: 50%;
    }
    .elementor-107 .elementor-element.elementor-element-216e761 {
      width: 49.998%;
    }
    .elementor-107 .elementor-element.elementor-element-111b16d {
      width: 50%;
    }
    .elementor-107 .elementor-element.elementor-element-67d9331 {
      width: 49.998%;
    }
    .elementor-107 .elementor-element.elementor-element-114e580 {
      width: 50%;
    }
    .elementor-107 .elementor-element.elementor-element-4a879b6 {
      width: 49.998%;
    }
    .elementor-107 .elementor-element.elementor-element-9a59b61 {
      width: 48.333%;
    }
    .elementor-107 .elementor-element.elementor-element-3182c0c {
      width: 51.667%;
    }
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    .elementor-107 .elementor-element.elementor-element-331ce52 {
      width: 35%;
    }
    .elementor-107 .elementor-element.elementor-element-216e761 {
      width: 65%;
    }
    .elementor-107 .elementor-element.elementor-element-111b16d {
      width: 35%;
    }
    .elementor-107 .elementor-element.elementor-element-67d9331 {
      width: 65%;
    }
    .elementor-107 .elementor-element.elementor-element-114e580 {
      width: 35%;
    }
    .elementor-107 .elementor-element.elementor-element-4a879b6 {
      width: 65%;
    }
  }
  @media (min-width: 1025px) {
    .elementor-107
      .elementor-element.elementor-element-bac71f5:not(
        .elementor-motion-effects-element-type-background
      ) {
      background-attachment: fixed;
    }
  }
  @media (max-width: 768px) {
    .col-lg-4.col-md-6.banner-right {
      width: 574px;
      margin: 0 auto;
    }
    .elementor-107 .elementor-element.elementor-element-1fc58cd {
      display: none;
    }
    .elementor-107 .elementor-element.elementor-element-a790e66 {
      width: 100%; 
    }
  }
  @media (max-width: 1024px) {
    .elementor-107 .elementor-element.elementor-element-8a5cded {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 100px 0px 80px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-095da5f {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px 15px 20px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-1fc58cd.elementor-column.elementor-element[data-element_type="column"]
      > .elementor-widget-wrap.elementor-element-populated {
      align-content: center;
      align-items: center;
    }
    .elementor-107
      .elementor-element.elementor-element-1fc58cd
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-3b6e185
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    body:not(.rtl) .elementor-107 .elementor-element.elementor-element-3b6e185 {
      left: 15vw;
    }
    .elementor-107 .elementor-element.elementor-element-3b6e185 {
      top: -99px;
    }
    .elementor-107 .elementor-element.elementor-element-3b6e185 img {
      max-width: 50%;
    }
    .elementor-107
      .elementor-element.elementor-element-96c6cb6
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-e6d2072
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-e6d2072 {
      font-size: 18px;
      line-height: 1.1em;
    }
    body:not(.rtl) .elementor-107 .elementor-element.elementor-element-39f21ae {
      left: 0px;
    }
    .elementor-107 .elementor-element.elementor-element-39f21ae {
      top: 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-a790e66.elementor-column.elementor-element[data-element_type="column"]
      > .elementor-widget-wrap.elementor-element-populated {
      align-content: center;
      align-items: center;
    }
    body:not(.rtl) .elementor-107 .elementor-element.elementor-element-0859072 {
      left: 0px;
    }
    .elementor-107 .elementor-element.elementor-element-0859072 {
      top: 0px;
    }
    .elementor-107 .elementor-element.elementor-element-8da5c64 {
      width: auto;
      max-width: auto;
    }
    .elementor-107
      .elementor-element.elementor-element-8da5c64
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-8da5c64 img {
      max-width: 50vw;
    }
    .elementor-107 .elementor-element.elementor-element-9ad998a {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 50px 15px 20px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-2d8aa13
      > .elementor-element-populated {
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-1b9a9b5
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-1b9a9b5
      .elementor-heading-title {
      font-size: var(--e-global-typography-b2c0f34-font-size);
      line-height: var(--e-global-typography-b2c0f34-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-7ca5669
      > .elementor-widget-container {
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-7ca5669
      .elementor-heading-title {
      font-size: 40px;
      line-height: 1.1em;
    }
    .elementor-107
      .elementor-element.elementor-element-43129fb
      > .elementor-container {
      max-width: 700px;
    }
    .elementor-107 .elementor-element.elementor-element-43129fb {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 10px 10px 0px 10px;
    }
    .elementor-107
      .elementor-element.elementor-element-d94d17c
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-9766f41
      > .elementor-widget-container {
      margin: 0px 10px 0px 10px;
      padding: 25px 12px 25px 12px;
    }
    .elementor-107
      .elementor-element.elementor-element-9766f41
      .elementor-image-box-wrapper
      .elementor-image-box-img {
      width: 40%;
    }
    .elementor-107
      .elementor-element.elementor-element-9766f41
      .elementor-image-box-description {
      font-size: 16px;
      line-height: 1.1em;
    }
    .elementor-107
      .elementor-element.elementor-element-095a268
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-aacfa86
      > .elementor-widget-container {
      margin: 0px 10px 0px 10px;
      padding: 25px 12px 25px 12px;
    }
    .elementor-107
      .elementor-element.elementor-element-aacfa86
      .elementor-image-box-wrapper
      .elementor-image-box-img {
      width: 40%;
    }
    .elementor-107
      .elementor-element.elementor-element-aacfa86
      .elementor-image-box-description {
      font-size: 16px;
      line-height: 1.1em;
    }
    .elementor-107
      .elementor-element.elementor-element-8a1b7c5
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-c58621c
      > .elementor-widget-container {
      margin: 0px 10px 0px 10px;
      padding: 25px 12px 25px 12px;
    }
    .elementor-107
      .elementor-element.elementor-element-c58621c
      .elementor-image-box-wrapper
      .elementor-image-box-img {
      width: 40%;
    }
    .elementor-107
      .elementor-element.elementor-element-c58621c
      .elementor-image-box-description {
      font-size: 16px;
      line-height: 1.1em;
    }
    .elementor-107 .elementor-element.elementor-element-bac71f5 {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 80px 0px 80px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-f97ba5d
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-f97ba5d
      .elementor-heading-title {
      font-size: var(--e-global-typography-b2c0f34-font-size);
      line-height: var(--e-global-typography-b2c0f34-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-84d937f
      > .elementor-widget-container {
      margin: 0px 0px 25px 0px;
      padding: 0px 115px 0px 115px;
    }
    .elementor-107 .elementor-element.elementor-element-4b4ed06 {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px 10px 0px 10px;
    }
    .elementor-107
      .elementor-element.elementor-element-b9a8bce.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-107
      .elementor-element.elementor-element-b9a8bce
      > .elementor-element-populated {
      margin: 0px 8px 0px 8px;
      --e-column-margin-right: 8px;
      --e-column-margin-left: 8px;
      padding: 10px 6px 10px 6px;
    }
    .elementor-107
      .elementor-element.elementor-element-409a356
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-409a356 {
      --icon-box-icon-margin: 10px;
    }
    .elementor-107
      .elementor-element.elementor-element-409a356
      .elementor-icon {
      font-size: 14px;
    }
    .elementor-107
      .elementor-element.elementor-element-1bed2fc
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-1bed2fc {
      text-align: center;
    }
    .elementor-107
      .elementor-element.elementor-element-1bed2fc
      .elementor-heading-title {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-107 .elementor-element.elementor-element-93f4af9 img {
      max-width: 95%;
    }
    .elementor-107
      .elementor-element.elementor-element-cf0b9b4
      > .elementor-widget-container {
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-cf0b9b4
      .elementor-divider-separator {
      width: 95%;
    }
    .elementor-107 .elementor-element.elementor-element-6d6edcb {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-331ce52.elementor-column.elementor-element[data-element_type="column"]
      > .elementor-widget-wrap.elementor-element-populated {
      align-content: center;
      align-items: center;
    }
    .elementor-107
      .elementor-element.elementor-element-331ce52.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-107
      .elementor-element.elementor-element-331ce52
      > .elementor-element-populated {
      padding: 0px 0px 0px 1px;
    }
    .elementor-107
      .elementor-element.elementor-element-8d8881a
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-216e761
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-d1d60ea
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 19px;
    }
    .elementor-107
      .elementor-element.elementor-element-d1d60ea
      .elementor-button {
      font-size: var(--e-global-typography-ef912e8-font-size);
      line-height: var(--e-global-typography-ef912e8-line-height);
      padding: 14px 10px 14px 10px;
    }
    .elementor-107
      .elementor-element.elementor-element-c2f26c6.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-107
      .elementor-element.elementor-element-c2f26c6
      > .elementor-element-populated {
      margin: 0px 8px 0px 8px;
      --e-column-margin-right: 8px;
      --e-column-margin-left: 8px;
      padding: 10px 6px 10px 6px;
    }
    .elementor-107
      .elementor-element.elementor-element-cc2f5fb
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-cc2f5fb {
      --icon-box-icon-margin: 10px;
    }
    .elementor-107
      .elementor-element.elementor-element-cc2f5fb
      .elementor-icon {
      font-size: 14px;
    }
    .elementor-107
      .elementor-element.elementor-element-61b7a6d
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-61b7a6d {
      text-align: center;
    }
    .elementor-107
      .elementor-element.elementor-element-61b7a6d
      .elementor-heading-title {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-107 .elementor-element.elementor-element-c68d10d img {
      max-width: 95%;
    }
    .elementor-107
      .elementor-element.elementor-element-275a9f0
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-275a9f0
      .elementor-divider-separator {
      width: 95%;
    }
    .elementor-107 .elementor-element.elementor-element-5e25983 {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-111b16d.elementor-column.elementor-element[data-element_type="column"]
      > .elementor-widget-wrap.elementor-element-populated {
      align-content: center;
      align-items: center;
    }
    .elementor-107
      .elementor-element.elementor-element-111b16d.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-107
      .elementor-element.elementor-element-111b16d
      > .elementor-element-populated {
      padding: 0px 0px 0px 1px;
    }
    .elementor-107
      .elementor-element.elementor-element-933d9fb
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-67d9331
      > .elementor-element-populated {
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-07d6603
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 19px;
    }
    .elementor-107
      .elementor-element.elementor-element-07d6603
      .elementor-button {
      font-size: var(--e-global-typography-ef912e8-font-size);
      line-height: var(--e-global-typography-ef912e8-line-height);
      padding: 14px 10px 14px 10px;
    }
    .elementor-107
      .elementor-element.elementor-element-feb5213.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-107
      .elementor-element.elementor-element-feb5213
      > .elementor-element-populated {
      margin: 0px 8px 0px 8px;
      --e-column-margin-right: 8px;
      --e-column-margin-left: 8px;
      padding: 10px 6px 10px 6px;
    }
    .elementor-107
      .elementor-element.elementor-element-dd24504
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-dd24504 {
      --icon-box-icon-margin: 10px;
    }
    .elementor-107
      .elementor-element.elementor-element-dd24504
      .elementor-icon {
      font-size: 14px;
    }
    .elementor-107 .elementor-element.elementor-element-c831e7d {
      text-align: center;
    }
    .elementor-107
      .elementor-element.elementor-element-c831e7d
      .elementor-heading-title {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-107 .elementor-element.elementor-element-55d2228 img {
      max-width: 95%;
    }
    .elementor-107
      .elementor-element.elementor-element-8c65830
      > .elementor-widget-container {
      margin: -8px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-9f2a9d6 {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-114e580.elementor-column.elementor-element[data-element_type="column"]
      > .elementor-widget-wrap.elementor-element-populated {
      align-content: center;
      align-items: center;
    }
    .elementor-107
      .elementor-element.elementor-element-114e580.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-107
      .elementor-element.elementor-element-114e580
      > .elementor-element-populated {
      padding: 0px 0px 0px 1px;
    }
    .elementor-107
      .elementor-element.elementor-element-3bdd496
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-4a879b6
      > .elementor-element-populated {
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-a1ed705
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 19px;
    }
    .elementor-107
      .elementor-element.elementor-element-a1ed705
      .elementor-button {
      font-size: var(--e-global-typography-ef912e8-font-size);
      line-height: var(--e-global-typography-ef912e8-line-height);
      padding: 14px 10px 14px 10px;
    }
    .elementor-107
      .elementor-element.elementor-element-87d89bd
      > .elementor-background-overlay {
      background-position: 0vw 0%;
      background-size: 1px auto;
    }
    .elementor-107 .elementor-element.elementor-element-87d89bd {
      padding: 80px 15px 50px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-9a59b61
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-bdbed02
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-bdbed02 img {
      width: 85%;
      border-width: 5px 5px 5px 5px;
      border-radius: 10px 10px 10px 10px;
    }
    body:not(.rtl) .elementor-107 .elementor-element.elementor-element-8a1e635 {
      left: 133px;
    }
    .elementor-107 .elementor-element.elementor-element-8a1e635 {
      top: 322px;
      text-align: center;
    }
    .elementor-107 .elementor-element.elementor-element-8a1e635 img {
      width: 90%;
      max-width: 85%;
      border-width: 5px 5px 5px 5px;
      border-radius: 10px 10px 10px 10px;
    }
    .elementor-107
      .elementor-element.elementor-element-3182c0c
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-322181b
      .elementor-heading-title {
      font-size: var(--e-global-typography-b2c0f34-font-size);
      line-height: var(--e-global-typography-b2c0f34-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-19b8ce8
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-19b8ce8 {
      font-size: 16px;
      line-height: 1.1em;
    }
    .elementor-107
      .elementor-element.elementor-element-1dd8048
      > .elementor-background-overlay {
      background-position: 0% 0%;
      background-size: 1px auto;
    }
    .elementor-107 .elementor-element.elementor-element-1dd8048 {
      padding: 80px 0px 60px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-4b93d44
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-048826c
      > .elementor-widget-container {
      margin: 0px 0px 4px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-fa0c29a
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-aacba8a {
      margin-top: 15px;
      margin-bottom: 0px;
      padding: 0px 15px 0px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-54cbbc0
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 5px 0px 5px;
    }
    .elementor-107
      .elementor-element.elementor-element-4645267
      > .elementor-widget-container {
      margin: -100px 0px 0px 0px;
      padding: 0px 0px 0px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-4645267
      .elementor-icon-box-title {
      font-size: var(--e-global-typography-3de61a3-font-size);
      line-height: var(--e-global-typography-3de61a3-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-4645267
      .elementor-icon-box-description {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-bb1bb26
      > .elementor-element-populated {
      padding: 0px 5px 0px 5px;
    }
    .elementor-107
      .elementor-element.elementor-element-fff12ce
      > .elementor-widget-container {
      margin: -100px 0px 0px 0px;
      padding: 0px 0px 0px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-fff12ce
      .elementor-icon-box-title {
      font-size: var(--e-global-typography-3de61a3-font-size);
      line-height: var(--e-global-typography-3de61a3-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-fff12ce
      .elementor-icon-box-description {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-684e0f4
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 5px 0px 5px;
    }
    .elementor-107
      .elementor-element.elementor-element-35895bb
      > .elementor-widget-container {
      margin: -100px 0px 0px 0px;
      padding: 0px 0px 0px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-35895bb
      .elementor-icon-box-title {
      font-size: var(--e-global-typography-3de61a3-font-size);
      line-height: var(--e-global-typography-3de61a3-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-35895bb
      .elementor-icon-box-description {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
  }
  @media (max-width: 767px) {
    #myTabContent .form-group {
      width: 93%;
    }
    .banner-right .form-wrap .form-control, .react-datepicker-wrapper {
      width: 100% !important;
    }
    .elementor-107 .elementor-element.elementor-element-8a5cded {
      /* padding: 60px 0px 50px 0px; */
      padding: 100px 0px 50px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-7e4b527
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-095da5f {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px 15px 20px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-96c6cb6
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-96c6cb6
      .elementor-icon-box-wrapper {
      text-align: center;
    }
    .elementor-107 .elementor-element.elementor-element-96c6cb6 {
      --icon-box-icon-margin: 13px;
    }
    .elementor-107
      .elementor-element.elementor-element-96c6cb6
      .elementor-icon {
      font-size: 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-6c5b724
      .elementor-headline {
      text-align: center;
      font-size: 43px;
    }
    .elementor-107
      .elementor-element.elementor-element-e6d2072
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 15px 0px 15px;
    }
    .elementor-107 .elementor-element.elementor-element-e6d2072 {
      text-align: center;
      font-size: 16px;
      line-height: 1.4em;
    }
    body:not(.rtl) .elementor-107 .elementor-element.elementor-element-39f21ae {
      left: 0px;
    }
    .elementor-107 .elementor-element.elementor-element-39f21ae {
      top: 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-a790e66.elementor-column.elementor-element[data-element_type="column"]
      > .elementor-widget-wrap.elementor-element-populated {
      align-content: center;
      align-items: center;
    }
    .elementor-107
      .elementor-element.elementor-element-a790e66
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    body:not(.rtl) .elementor-107 .elementor-element.elementor-element-0859072 {
      left: 0px;
    }
    .elementor-107 .elementor-element.elementor-element-0859072 {
      top: 0px;
    }
    .elementor-107 .elementor-element.elementor-element-8da5c64 {
      width: 100%;
      max-width: 100%;
      text-align: center;
    }
    .elementor-107
      .elementor-element.elementor-element-8da5c64
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-8da5c64 img {
      max-width: 85vw;
    }
    .elementor-107 .elementor-element.elementor-element-9ad998a {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 30px 15px 20px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-2d8aa13
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-1b9a9b5 {
      text-align: center;
    }
    .elementor-107
      .elementor-element.elementor-element-1b9a9b5
      .elementor-heading-title {
      font-size: var(--e-global-typography-b2c0f34-font-size);
      line-height: var(--e-global-typography-b2c0f34-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-7ca5669
      > .elementor-widget-container {
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-7ca5669 {
      text-align: center;
    }
    .elementor-107
      .elementor-element.elementor-element-7ca5669
      .elementor-heading-title {
      font-size: 30px;
      line-height: 1.1em;
    }
    .elementor-107 .elementor-element.elementor-element-43129fb {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 20px 5px 20px 5px;
    }
    .elementor-107
      .elementor-element.elementor-element-d94d17c
      > .elementor-element-populated {
      margin: 0px 0px 20px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 15px 0px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-9766f41
      .elementor-image-box-img {
      margin-bottom: 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-9766f41
      .elementor-image-box-wrapper
      .elementor-image-box-img {
      width: 30%;
    }
    .elementor-107
      .elementor-element.elementor-element-9766f41
      .elementor-image-box-title {
      font-size: 24px;
    }
    .elementor-107
      .elementor-element.elementor-element-9766f41
      .elementor-image-box-description {
      font-size: 16px;
      line-height: 1.4em;
    }
    .elementor-107
      .elementor-element.elementor-element-095a268
      > .elementor-element-populated {
      margin: 0px 0px 20px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 15px 0px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-aacfa86
      .elementor-image-box-img {
      margin-bottom: 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-aacfa86
      .elementor-image-box-wrapper
      .elementor-image-box-img {
      width: 30%;
    }
    .elementor-107
      .elementor-element.elementor-element-aacfa86
      .elementor-image-box-title {
      font-size: 24px;
    }
    .elementor-107
      .elementor-element.elementor-element-aacfa86
      .elementor-image-box-description {
      font-size: 16px;
      line-height: 1.4em;
    }
    .elementor-107
      .elementor-element.elementor-element-8a1b7c5
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 15px 0px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-c58621c
      .elementor-image-box-img {
      margin-bottom: 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-c58621c
      .elementor-image-box-wrapper
      .elementor-image-box-img {
      width: 30%;
    }
    .elementor-107
      .elementor-element.elementor-element-c58621c
      .elementor-image-box-title {
      font-size: 24px;
    }
    .elementor-107
      .elementor-element.elementor-element-c58621c
      .elementor-image-box-description {
      font-size: 16px;
      line-height: 1.4em;
    }
    .elementor-107 .elementor-element.elementor-element-bac71f5 {
      padding: 50px 0px 50px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-f97ba5d
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 10px 0px 10px;
    }
    .elementor-107
      .elementor-element.elementor-element-f97ba5d
      .elementor-heading-title {
      font-size: var(--e-global-typography-b2c0f34-font-size);
      line-height: var(--e-global-typography-b2c0f34-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-84d937f
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-84d937f
      .elementor-heading-title {
      font-size: 30px;
    }
    .elementor-107 .elementor-element.elementor-element-4b4ed06 {
      padding: 20px 15px 0px 15px;
    }
    .elementor-107
      .elementor-element.elementor-element-b9a8bce
      > .elementor-element-populated {
      margin: 0px 15px 20px 15px;
      --e-column-margin-right: 15px;
      --e-column-margin-left: 15px;
      padding: 15px 0px 15px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-409a356
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 62px;
    }
    .elementor-107
      .elementor-element.elementor-element-1bed2fc
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-1bed2fc
      .elementor-heading-title {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-107 .elementor-element.elementor-element-93f4af9 img {
      max-width: 95%;
    }
    .elementor-107
      .elementor-element.elementor-element-cf0b9b4
      > .elementor-widget-container {
      margin: -8px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-cf0b9b4
      .elementor-divider-separator {
      width: 95%;
    }
    .elementor-107 .elementor-element.elementor-element-331ce52 {
      width: 50%;
    }
    .elementor-107
      .elementor-element.elementor-element-331ce52.elementor-column.elementor-element[data-element_type="column"]
      > .elementor-widget-wrap.elementor-element-populated {
      align-content: center;
      align-items: center;
    }
    .elementor-107
      .elementor-element.elementor-element-331ce52.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-107
      .elementor-element.elementor-element-a3f5743
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 3px;
    }
    .elementor-107 .elementor-element.elementor-element-216e761 {
      width: 50%;
    }
    .elementor-107
      .elementor-element.elementor-element-d1d60ea
      .elementor-button {
      font-size: var(--e-global-typography-ef912e8-font-size);
      line-height: var(--e-global-typography-ef912e8-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-c2f26c6
      > .elementor-element-populated {
      margin: 0px 15px 20px 15px;
      --e-column-margin-right: 15px;
      --e-column-margin-left: 15px;
      padding: 15px 0px 15px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-cc2f5fb
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 62px;
    }
    .elementor-107
      .elementor-element.elementor-element-61b7a6d
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-61b7a6d
      .elementor-heading-title {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-107 .elementor-element.elementor-element-c68d10d img {
      max-width: 95%;
    }
    .elementor-107
      .elementor-element.elementor-element-275a9f0
      > .elementor-widget-container {
      margin: -8px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-275a9f0
      .elementor-divider {
      padding-block-start: 2px;
      padding-block-end: 2px;
    }
    .elementor-107 .elementor-element.elementor-element-111b16d {
      width: 50%;
    }
    .elementor-107
      .elementor-element.elementor-element-111b16d.elementor-column.elementor-element[data-element_type="column"]
      > .elementor-widget-wrap.elementor-element-populated {
      align-content: center;
      align-items: center;
    }
    .elementor-107
      .elementor-element.elementor-element-111b16d.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-107
      .elementor-element.elementor-element-7ed077f
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 3px;
    }
    .elementor-107 .elementor-element.elementor-element-67d9331 {
      width: 50%;
    }
    .elementor-107
      .elementor-element.elementor-element-07d6603
      .elementor-button {
      font-size: var(--e-global-typography-ef912e8-font-size);
      line-height: var(--e-global-typography-ef912e8-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-feb5213
      > .elementor-element-populated {
      margin: 0px 15px 0px 15px;
      --e-column-margin-right: 15px;
      --e-column-margin-left: 15px;
      padding: 15px 0px 15px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-dd24504
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 54px;
    }
    .elementor-107
      .elementor-element.elementor-element-c831e7d
      > .elementor-widget-container {
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-c831e7d
      .elementor-heading-title {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-107 .elementor-element.elementor-element-55d2228 img {
      max-width: 95%;
    }
    .elementor-107 .elementor-element.elementor-element-114e580 {
      width: 50%;
    }
    .elementor-107
      .elementor-element.elementor-element-c164a3d
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 3px;
    }
    .elementor-107 .elementor-element.elementor-element-4a879b6 {
      width: 50%;
    }
    .elementor-107
      .elementor-element.elementor-element-a1ed705
      .elementor-button {
      font-size: var(--e-global-typography-ef912e8-font-size);
      line-height: var(--e-global-typography-ef912e8-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-87d89bd
      > .elementor-background-overlay {
      background-position: 0vw 0%;
    }
    .elementor-107 .elementor-element.elementor-element-87d89bd {
      padding: 50px 15px 50px 15px;
    }
    .elementor-107 .elementor-element.elementor-element-bdbed02 img {
      width: 85%;
      height: 288px;
    }
    body:not(.rtl) .elementor-107 .elementor-element.elementor-element-8a1e635 {
      left: 133px;
    }
    .elementor-107 .elementor-element.elementor-element-8a1e635 {
      top: 174px;
    }
    .elementor-107 .elementor-element.elementor-element-8a1e635 img {
      width: 85%;
      height: 131px;
    }
    .elementor-107
      .elementor-element.elementor-element-3182c0c
      > .elementor-element-populated {
      margin: 20px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107 .elementor-element.elementor-element-322181b {
      text-align: center;
    }
    .elementor-107
      .elementor-element.elementor-element-322181b
      .elementor-heading-title {
      font-size: var(--e-global-typography-b2c0f34-font-size);
      line-height: var(--e-global-typography-b2c0f34-line-height);
    }
    .elementor-107 .elementor-element.elementor-element-6af5a4d {
      text-align: center;
    }
    .elementor-107
      .elementor-element.elementor-element-6af5a4d
      .elementor-heading-title {
      font-size: 30px;
    }
    .elementor-107 .elementor-element.elementor-element-19b8ce8 {
      text-align: center;
      font-size: 16px;
      line-height: 1.4em;
    }
    .elementor-107
      .elementor-element.elementor-element-1dd8048
      > .elementor-background-overlay {
      background-position: 0% 0%;
    }
    .elementor-107 .elementor-element.elementor-element-1dd8048 {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 50px 15px 50px 15px;
    }
    body:not(.rtl) .elementor-107 .elementor-element.elementor-element-2a52f2f {
      left: 0px;
    }
    .elementor-107 .elementor-element.elementor-element-2a52f2f {
      top: 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-048826c
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-fa0c29a
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 5px 0px 5px;
    }
    .elementor-107 .elementor-element.elementor-element-aacba8a {
      margin-top: 10px;
      margin-bottom: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-54cbbc0
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-4645267
      .elementor-icon-box-title {
      font-size: var(--e-global-typography-3de61a3-font-size);
      line-height: var(--e-global-typography-3de61a3-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-4645267
      .elementor-icon-box-description {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-bb1bb26
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-fff12ce
      .elementor-icon-box-title {
      font-size: var(--e-global-typography-3de61a3-font-size);
      line-height: var(--e-global-typography-3de61a3-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-fff12ce
      .elementor-icon-box-description {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-684e0f4
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-107
      .elementor-element.elementor-element-35895bb
      .elementor-icon-box-title {
      font-size: var(--e-global-typography-3de61a3-font-size);
      line-height: var(--e-global-typography-3de61a3-line-height);
    }
    .elementor-107
      .elementor-element.elementor-element-35895bb
      .elementor-icon-box-description {
      font-size: var(--e-global-typography-1e647e3-font-size);
      line-height: var(--e-global-typography-1e647e3-line-height);
    }
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/uploads/elementor/css/post-161.css?ver=1728936493 ; media=all */
@media all {
  .elementor-161
    .elementor-element.elementor-element-52593114:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: transparent;
    background-image: linear-gradient(
      90deg,
      var(--e-global-color-secondary) 100%,
      var(--e-global-color-c03bcf0) 100%
    );
  }
  .elementor-161
    .elementor-element.elementor-element-52593114
    > .elementor-container {
    max-width: 1175px;
  }
  .elementor-161 .elementor-element.elementor-element-52593114 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 8px 0px 8px 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-732ed9d2.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-161
    .elementor-element.elementor-element-732ed9d2
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-64bf395c
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 33px;
  }
  .elementor-161
    .elementor-element.elementor-element-64bf395c
    .elementor-icon-list-icon
    svg {
    fill: var(--e-global-color-primary);
    transition: fill 0.3s;
  }
  .elementor-161
    .elementor-element.elementor-element-64bf395c
    .elementor-icon-list-item:hover
    .elementor-icon-list-icon
    svg {
    fill: var(--e-global-color-accent);
  }
  .elementor-161 .elementor-element.elementor-element-64bf395c {
    --e-icon-list-icon-size: 20px;
    --icon-vertical-offset: 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-64bf395c
    .elementor-icon-list-icon {
    padding-right: 1px;
  }
  .elementor-161
    .elementor-element.elementor-element-64bf395c
    .elementor-icon-list-item
    > a {
    font-family: var(--e-global-typography-126ffe0-font-family), Sans-serif;
    font-size: var(--e-global-typography-126ffe0-font-size);
    font-weight: var(--e-global-typography-126ffe0-font-weight);
    text-decoration: var(--e-global-typography-126ffe0-text-decoration);
    line-height: var(--e-global-typography-126ffe0-line-height);
    letter-spacing: var(--e-global-typography-126ffe0-letter-spacing);
  }
  .elementor-161
    .elementor-element.elementor-element-64bf395c
    .elementor-icon-list-text {
    color: var(--e-global-color-primary);
    transition: color 0.3s;
  }
  .elementor-161
    .elementor-element.elementor-element-4a6fa710.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-161
    .elementor-element.elementor-element-4a6fa710.elementor-column
    > .elementor-widget-wrap {
    justify-content: flex-end;
  }
  .elementor-161
    .elementor-element.elementor-element-4a6fa710
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-2217b677
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 29px 0px 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-2217b677
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-primary);
    transition: color 0.3s;
  }
  .elementor-161
    .elementor-element.elementor-element-2217b677
    .elementor-icon-list-item:hover
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-accent);
  }
  .elementor-161 .elementor-element.elementor-element-2217b677 {
    --e-icon-list-icon-size: 20px;
    --icon-vertical-align: center;
    --icon-vertical-offset: 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-2217b677
    .elementor-icon-list-icon {
    padding-right: 5px;
  }
  .elementor-161
    .elementor-element.elementor-element-2217b677
    .elementor-icon-list-item
    > a {
    font-family: var(--e-global-typography-126ffe0-font-family), Sans-serif;
    font-size: var(--e-global-typography-126ffe0-font-size);
    font-weight: var(--e-global-typography-126ffe0-font-weight);
    text-decoration: var(--e-global-typography-126ffe0-text-decoration);
    line-height: var(--e-global-typography-126ffe0-line-height);
    letter-spacing: var(--e-global-typography-126ffe0-letter-spacing);
  }
  .elementor-161
    .elementor-element.elementor-element-2217b677
    .elementor-icon-list-text {
    color: var(--e-global-color-primary);
    transition: color 0.3s;
  }
  .elementor-161
    .elementor-element.elementor-element-63786e5f:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: var(--e-global-color-accent);
  }
  .elementor-161 .elementor-element.elementor-element-63786e5f {
    box-shadow: 0px 20px 50px 0px rgba(30, 37.84347826086956, 194, 0.35);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
    z-index: 10;
  }
  .elementor-161
    .elementor-element.elementor-element-49aa43eb.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-161
    .elementor-element.elementor-element-3bb29277
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-161 .elementor-element.elementor-element-3bb29277 {
    text-align: left;
  }
  .elementor-161
    .elementor-element.elementor-element-32eea1ab.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-161
    .elementor-element.elementor-element-32eea1ab
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-menu-hamburger {
    background-color: var(--e-global-color-c03bcf0);
    float: right;
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-menu-close {
    background-color: var(--e-global-color-c03bcf0);
    font-family: var(--e-global-typography-text-font-family), Sans-serif;
    color: var(--e-global-color-primary);
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-menu-container {
    height: 45px;
    border-radius: 0px 0px 0px 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-nav-identity-panel {
    padding: 0px 0px 0px 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-navbar-nav
    > li
    > a {
    font-family: "Jost", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: var(--e-global-color-c03bcf0);
    border-radius: 0px 0px 0px 0px;
    padding: 0px 24px 0px 30px;
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-navbar-nav
    > li
    > a:hover {
    color: var(--e-global-color-secondary);
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-navbar-nav
    > li
    > a:focus {
    color: var(--e-global-color-secondary);
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-navbar-nav
    > li
    > a:active {
    color: var(--e-global-color-secondary);
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-navbar-nav
    > li:hover
    > a {
    color: var(--e-global-color-secondary);
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-navbar-nav
    > li.current-menu-item
    > a {
    color: var(--e-global-color-secondary);
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-menu-hamburger
    .elementskit-menu-hamburger-icon {
    background-color: var(--e-global-color-primary);
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-menu-hamburger:hover
    .elementskit-menu-hamburger-icon {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .elementor-161
    .elementor-element.elementor-element-22a51f93
    .elementskit-menu-close:hover {
    color: rgba(0, 0, 0, 0.5);
  }
  .elementor-161
    .elementor-element.elementor-element-444f021f.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-161
    .elementor-element.elementor-element-444f021f
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-6c5ca80
    .elementor-button {
    background-color: var(--e-global-color-secondary);
    font-family: var(--e-global-typography-b756bfd-font-family), Sans-serif;
    font-size: var(--e-global-typography-b756bfd-font-size);
    font-weight: var(--e-global-typography-b756bfd-font-weight);
    line-height: var(--e-global-typography-b756bfd-line-height);
    fill: var(--e-global-color-c03bcf0);
    color: var(--e-global-color-c03bcf0);
    border-radius: 30px 30px 30px 30px;
    box-shadow: 0px 10px 50px 0px rgba(255, 137.00000000000003, 0, 0.12);
    padding: 13px 21px 10px 21px;
  }
  .elementor-161 .elementor-element.elementor-element-6c5ca80 {
    width: 100%;
    max-width: 100%;
  }
  .elementor-161
    .elementor-element.elementor-element-6c5ca80
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-161
    .elementor-element.elementor-element-6c5ca80
    .elementor-button-content-wrapper {
    flex-direction: row;
  }
  @media (max-width: 1024px) {
    .elementor-161 .elementor-element.elementor-element-52593114 {
      padding: 6px 15px 6px 15px;
    }
    .elementor-161
      .elementor-element.elementor-element-732ed9d2
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-161
      .elementor-element.elementor-element-64bf395c
      > .elementor-widget-container {
      padding: 0px 0px 0px 0px;
    }
    .elementor-161 .elementor-element.elementor-element-64bf395c {
      --e-icon-list-icon-size: 14px;
    }
    .elementor-161
      .elementor-element.elementor-element-64bf395c
      .elementor-icon-list-item
      > a {
      font-size: var(--e-global-typography-126ffe0-font-size);
      line-height: var(--e-global-typography-126ffe0-line-height);
      letter-spacing: var(--e-global-typography-126ffe0-letter-spacing);
    }
    .elementor-161
      .elementor-element.elementor-element-4a6fa710
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-161
      .elementor-element.elementor-element-2217b677
      > .elementor-widget-container {
      padding: 0px 0px 0px 0px;
    }
    .elementor-161 .elementor-element.elementor-element-2217b677 {
      --e-icon-list-icon-size: 14px;
    }
    .elementor-161
      .elementor-element.elementor-element-2217b677
      .elementor-icon-list-item
      > a {
      font-size: var(--e-global-typography-126ffe0-font-size);
      line-height: var(--e-global-typography-126ffe0-line-height);
      letter-spacing: var(--e-global-typography-126ffe0-letter-spacing);
    }
    .elementor-161 .elementor-element.elementor-element-63786e5f {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 10px 15px 10px 15px;
    }
    .elementor-161
      .elementor-element.elementor-element-49aa43eb
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-161
      .elementor-element.elementor-element-3bb29277
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-161 .elementor-element.elementor-element-3bb29277 img {
      /* max-width: 90%; */
      width: 135px;
    }
    .elementor-161
      .elementor-element.elementor-element-32eea1ab
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-161 .elementor-element.elementor-element-22a51f93 {
      z-index: 5;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-nav-identity-panel {
      padding: 0px 0px 0px 0px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-menu-container {
      max-width: 400px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-navbar-nav
      > li
      > a {
      font-size: 16px;
      line-height: 1.1em;
      color: var(--e-global-color-primary);
      padding: 20px 30px 20px 30px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-navbar-nav
      > li {
      margin: 0px 0px 0px 0px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-menu-hamburger {
      padding: 13px 10px 13px 10px;
      border-radius: 5px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-menu-close {
      padding: 5px 5px 5px 5px;
      margin: 10px 13px 10px 13px;
      width: 45px;
      border-radius: 5px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-nav-logo
      > img {
      max-width: 160px;
      max-height: 60px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-nav-logo {
      margin: 5px 0px 5px 0px;
      padding: 5px 5px 5px 5px;
    }
    .elementor-161
      .elementor-element.elementor-element-6c5ca80
      .elementor-button {
      font-size: var(--e-global-typography-b756bfd-font-size);
      line-height: var(--e-global-typography-b756bfd-line-height);
    }
  }
  @media (max-width: 767px) {
    .elementor-161 .elementor-element.elementor-element-52593114 {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 10px 10px 10px 10px;
    }
    .elementor-161 .elementor-element.elementor-element-732ed9d2 {
      width: 50%;
    }
    .elementor-161
      .elementor-element.elementor-element-732ed9d2.elementor-column.elementor-element[data-element_type="column"]
      > .elementor-widget-wrap.elementor-element-populated {
      align-content: center;
      align-items: center;
    }
    .elementor-161
      .elementor-element.elementor-element-732ed9d2.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-161 .elementor-element.elementor-element-64bf395c {
      --e-icon-list-icon-size: 14px;
    }
    .elementor-161
      .elementor-element.elementor-element-64bf395c
      .elementor-icon-list-item
      > a {
      font-size: var(--e-global-typography-126ffe0-font-size);
      line-height: var(--e-global-typography-126ffe0-line-height);
      letter-spacing: var(--e-global-typography-126ffe0-letter-spacing);
    }
    .elementor-161 .elementor-element.elementor-element-4a6fa710 {
      width: 50%;
    }
    .elementor-161
      .elementor-element.elementor-element-4a6fa710.elementor-column.elementor-element[data-element_type="column"]
      > .elementor-widget-wrap.elementor-element-populated {
      align-content: center;
      align-items: center;
    }
    .elementor-161
      .elementor-element.elementor-element-4a6fa710.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-161
      .elementor-element.elementor-element-2217b677
      .elementor-icon-list-item
      > a {
      font-size: var(--e-global-typography-126ffe0-font-size);
      line-height: var(--e-global-typography-126ffe0-line-height);
      letter-spacing: var(--e-global-typography-126ffe0-letter-spacing);
    }
    .elementor-161 .elementor-element.elementor-element-63786e5f {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .elementor-161 .elementor-element.elementor-element-49aa43eb {
      width: 50%;
    }
    .elementor-161
      .elementor-element.elementor-element-49aa43eb.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-161
      .elementor-element.elementor-element-49aa43eb
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-161 .elementor-element.elementor-element-32eea1ab {
      width: 50%;
    }
    .elementor-161
      .elementor-element.elementor-element-32eea1ab.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-161 .elementor-element.elementor-element-22a51f93 {
      z-index: 10;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-menu-container {
      max-width: 300px;
      border-radius: 0px 0px 0px 0px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-navbar-nav
      > li
      > a {
      font-size: 16px;
      line-height: 1.1em;
      padding: 15px 15px 15px 15px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-navbar-nav
      > li {
      margin: 0px 0px 0px 0px;
    }
    .elementor-161
      .elementor-element.elementor-element-22a51f93
      .elementskit-nav-logo
      > img {
      max-width: 100px;
      max-height: 50px;
    }
    .elementor-161 .elementor-element.elementor-element-6c5ca80 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-161
      .elementor-element.elementor-element-6c5ca80
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-161
      .elementor-element.elementor-element-6c5ca80
      .elementor-button {
      font-size: var(--e-global-typography-b756bfd-font-size);
      line-height: var(--e-global-typography-b756bfd-line-height);
    }
  }
  @media (min-width: 768px) {
    .elementor-161 .elementor-element.elementor-element-49aa43eb {
      width: 16.314%;
    }
    .elementor-161 .elementor-element.elementor-element-32eea1ab {
      width: 66.743%;
    }
    .elementor-161 .elementor-element.elementor-element-444f021f {
      width: 14.993%;
    }
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    .elementor-161 .elementor-element.elementor-element-49aa43eb {
      width: 25%;
    }
    .elementor-161 .elementor-element.elementor-element-32eea1ab {
      width: 50%;
    }
    .elementor-161 .elementor-element.elementor-element-444f021f {
      width: 25%;
    }
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/uploads/elementor/css/post-173.css?ver=1728936493 ; media=all */
@media all {
  .elementor-173
    .elementor-element.elementor-element-b095fbe:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      var(--e-global-color-428be43) 0%,
      #ffffff00 100%
    );
  }
  .elementor-173 .elementor-element.elementor-element-b095fbe {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 80px 0px 6px 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-8d0049c
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-173 .elementor-element.elementor-element-84cf729 {
    --divider-border-style: solid;
    --divider-color: var(--e-global-color-accent);
    --divider-border-width: 2.9px;
  }
  .elementor-173
    .elementor-element.elementor-element-84cf729
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 5px 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-84cf729
    .elementor-divider-separator {
    width: 17%;
  }
  .elementor-173
    .elementor-element.elementor-element-84cf729
    .elementor-divider {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }
  .elementor-173
    .elementor-element.elementor-element-14e57b7
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 20px 0px 0px;
  }
  .elementor-173 .elementor-element.elementor-element-14e57b7 {
    font-family: "Jost", Sans-serif;
    font-size: 18px;
  }
  .elementor-173
    .elementor-element.elementor-element-7789ea8
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 6px 0px 0px 0px;
  }
  .elementor-173 .elementor-element.elementor-element-7789ea8 {
    text-align: left;
  }
  .elementor-173 .elementor-element.elementor-element-7789ea8 img {
    box-shadow: 0px 10px 10px 0px
      rgba(29.999999999999975, 193.99999999999997, 138.99999999999997, 0.06);
  }
  .elementor-173 .elementor-element.elementor-element-260b89c {
    --divider-border-style: solid;
    --divider-color: var(--e-global-color-accent);
    --divider-border-width: 2.9px;
  }
  .elementor-173
    .elementor-element.elementor-element-260b89c
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 5px 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-260b89c
    .elementor-divider-separator {
    width: 17%;
  }
  .elementor-173
    .elementor-element.elementor-element-260b89c
    .elementor-divider {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }
  .elementor-173
    .elementor-element.elementor-element-8a8a810
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-8a8a810
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(6px / 2);
  }
  .elementor-173
    .elementor-element.elementor-element-8a8a810
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(6px / 2);
  }
  .elementor-173
    .elementor-element.elementor-element-8a8a810
    .elementor-icon-list-icon
    svg {
    fill: var(--e-global-color-accent);
    transition: fill 0.3s;
  }
  .elementor-173 .elementor-element.elementor-element-8a8a810 {
    --e-icon-list-icon-size: 14px;
    --e-icon-list-icon-align: left;
    --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.25)
      0 0;
    --icon-vertical-offset: 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-8a8a810
    .elementor-icon-list-icon {
    padding-right: 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-8a8a810
    .elementor-icon-list-item
    > a {
    font-family: "Jost", Sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  .elementor-173
    .elementor-element.elementor-element-8a8a810
    .elementor-icon-list-text {
    color: var(--e-global-color-text);
    transition: color 0.3s;
  }
  .elementor-173
    .elementor-element.elementor-element-8a8a810
    .elementor-icon-list-item:hover
    .elementor-icon-list-text {
    color: var(--e-global-color-accent);
  }
  .elementor-173 .elementor-element.elementor-element-465603f {
    --divider-border-style: solid;
    --divider-color: var(--e-global-color-accent);
    --divider-border-width: 2.9px;
  }
  .elementor-173
    .elementor-element.elementor-element-465603f
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 5px 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-465603f
    .elementor-divider-separator {
    width: 17%;
  }
  .elementor-173
    .elementor-element.elementor-element-465603f
    .elementor-divider {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }
  .elementor-173
    .elementor-element.elementor-element-4b80727
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-4b80727
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(6px / 2);
  }
  .elementor-173
    .elementor-element.elementor-element-4b80727
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(6px / 2);
  }
  .elementor-173
    .elementor-element.elementor-element-4b80727
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-accent);
    transition: color 0.3s;
  }
  .elementor-173
    .elementor-element.elementor-element-4b80727
    .elementor-icon-list-item:hover
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-accent);
  }
  .elementor-173 .elementor-element.elementor-element-4b80727 {
    --e-icon-list-icon-size: 20px;
    --e-icon-list-icon-align: left;
    --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.25)
      0 0;
    --icon-vertical-offset: 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-4b80727
    .elementor-icon-list-icon {
    padding-right: 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-4b80727
    .elementor-icon-list-item
    > a {
    font-family: "Jost", Sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  .elementor-173
    .elementor-element.elementor-element-4b80727
    .elementor-icon-list-text {
    color: var(--e-global-color-text);
    transition: color 0.3s;
  }
  .elementor-173
    .elementor-element.elementor-element-4b80727
    .elementor-icon-list-item:hover
    .elementor-icon-list-text {
    color: var(--e-global-color-accent);
  }
  .elementor-173 .elementor-element.elementor-element-cfa5122 {
    --grid-template-columns: repeat(0, auto);
    --icon-size: 18px;
    --grid-column-gap: 3px;
    --grid-row-gap: 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-cfa5122
    .elementor-widget-container {
    text-align: left;
  }
  .elementor-173
    .elementor-element.elementor-element-cfa5122
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-173
    .elementor-element.elementor-element-cfa5122
    .elementor-social-icon {
    background-color: var(--e-global-color-accent);
  }
  .elementor-173
    .elementor-element.elementor-element-cfa5122
    .elementor-social-icon
    i {
    color: var(--e-global-color-secondary);
  }
  .elementor-173
    .elementor-element.elementor-element-cfa5122
    .elementor-social-icon
    svg {
    fill: var(--e-global-color-secondary);
  }
  .elementor-173
    .elementor-element.elementor-element-cfa5122
    .elementor-social-icon:hover {
    background-color: var(--e-global-color-accent);
  }
  .elementor-173
    .elementor-element.elementor-element-cfa5122
    .elementor-social-icon:hover
    i {
    color: var(--e-global-color-c03bcf0);
  }
  .elementor-173
    .elementor-element.elementor-element-cfa5122
    .elementor-social-icon:hover
    svg {
    fill: var(--e-global-color-c03bcf0);
  }
  .elementor-173 .elementor-element.elementor-element-a4777ff {
    --spacer-size: 30px;
  }
  .elementor-173 .elementor-element.elementor-element-48c1e64 {
    --divider-border-style: solid;
    --divider-color: #60648052;
    --divider-border-width: 1px;
  }
  .elementor-173
    .elementor-element.elementor-element-48c1e64
    .elementor-divider-separator {
    width: 100%;
  }
  .elementor-173
    .elementor-element.elementor-element-48c1e64
    .elementor-divider {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }
  .elementor-173
    .elementor-element.elementor-element-454c8f5
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 5px 0px 0px 5px;
  }
  .elementor-173 .elementor-element.elementor-element-454c8f5 {
    text-align: center;
  }
  @media (max-width: 1024px) {
    .elementor-173
      .elementor-element.elementor-element-b095fbe
      > .elementor-container {
      max-width: 700px;
    }
    .elementor-173 .elementor-element.elementor-element-b095fbe {
      padding: 40px 0px 20px 0px;
    }
    .elementor-173 .elementor-element.elementor-element-14e57b7 {
      font-size: 15px;
    }
    .elementor-173
      .elementor-element.elementor-element-e9cbe51
      > .elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 5px;
    }
    .elementor-173
      .elementor-element.elementor-element-8a8a810
      .elementor-icon-list-item
      > a {
      font-size: 15px;
    }
    .elementor-173
      .elementor-element.elementor-element-f0163f8
      > .elementor-element-populated {
      padding: 0px 0px 0px 5px;
    }
    .elementor-173
      .elementor-element.elementor-element-4b80727
      .elementor-icon-list-item
      > a {
      font-size: 15px;
    }
    .elementor-173 .elementor-element.elementor-element-a4777ff {
      --spacer-size: 30px;
    }
  }
  @media (max-width: 767px) {
    .elementor-173
      .elementor-element.elementor-element-569ccc8
      > .elementor-element-populated {
      margin: 0px 15px 0px 15px;
      --e-column-margin-right: 15px;
      --e-column-margin-left: 15px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-173 .elementor-element.elementor-element-708d266 {
      margin-top: 50px;
      margin-bottom: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-173 .elementor-element.elementor-element-82a0e46 {
      text-align: center;
    }
    .elementor-173
      .elementor-element.elementor-element-82a0e46
      .elementor-heading-title {
      font-size: 22px;
    }
    .elementor-173
      .elementor-element.elementor-element-84cf729
      .elementor-divider-separator {
      width: 20%;
      margin: 0 auto;
      margin-center: 0;
    }
    .elementor-173
      .elementor-element.elementor-element-84cf729
      .elementor-divider {
      text-align: center;
    }
    .elementor-173
      .elementor-element.elementor-element-14e57b7
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-173 .elementor-element.elementor-element-14e57b7 {
      text-align: center;
    }
    .elementor-173
      .elementor-element.elementor-element-7789ea8
      > .elementor-widget-container {
      margin: 0px 0px 20px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-173 .elementor-element.elementor-element-7789ea8 {
      text-align: center;
    }
    .elementor-173 .elementor-element.elementor-element-2ae7856 {
      text-align: center;
    }
    .elementor-173
      .elementor-element.elementor-element-2ae7856
      .elementor-heading-title {
      font-size: 22px;
    }
    .elementor-173
      .elementor-element.elementor-element-260b89c
      .elementor-divider-separator {
      width: 20%;
      margin: 0 auto;
      margin-center: 0;
    }
    .elementor-173
      .elementor-element.elementor-element-260b89c
      .elementor-divider {
      text-align: center;
    }
    .elementor-173
      .elementor-element.elementor-element-f0163f8.elementor-column
      > .elementor-widget-wrap {
      justify-content: center;
    }
    .elementor-173
      .elementor-element.elementor-element-f0163f8
      > .elementor-element-populated {
      margin: 20px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-173 .elementor-element.elementor-element-4f811af {
      text-align: center;
    }
    .elementor-173
      .elementor-element.elementor-element-4f811af
      .elementor-heading-title {
      font-size: 22px;
    }
    .elementor-173
      .elementor-element.elementor-element-465603f
      .elementor-divider-separator {
      width: 20%;
      margin: 0 auto;
      margin-center: 0;
    }
    .elementor-173
      .elementor-element.elementor-element-465603f
      .elementor-divider {
      text-align: center;
    }
    .elementor-173
      .elementor-element.elementor-element-cfa5122
      .elementor-widget-container {
      text-align: center;
    }
    .elementor-173 .elementor-element.elementor-element-a4777ff {
      --spacer-size: 10px;
    }
    .elementor-173
      .elementor-element.elementor-element-48c1e64
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
    .elementor-173
      .elementor-element.elementor-element-454c8f5
      > .elementor-widget-container {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
    }
  }
  @media (min-width: 768px) {
    .elementor-173 .elementor-element.elementor-element-8d0049c {
      width: 52.439%;
    }
    .elementor-173 .elementor-element.elementor-element-e9cbe51 {
      width: 22.258%;
    }
    .elementor-173 .elementor-element.elementor-element-f0163f8 {
      width: 24.94%;
    }
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    .elementor-173 .elementor-element.elementor-element-8d0049c {
      width: 45%;
    }
    .elementor-173 .elementor-element.elementor-element-e9cbe51 {
      width: 25%;
    }
    .elementor-173 .elementor-element.elementor-element-f0163f8 {
      width: 30%;
    }
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementskit-lite/modules/elementskit-icon-pack/assets/css/ekiticons.css?ver=3.2.8 ; media=all */
@media all {
  .icon,
  .icon::before {
    font-family: elementskit !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon.icon-phone1::before {
    content: "\e976";
  }
  .icon.icon-envelope2::before {
    content: "\ea13";
  }
  .icon.icon-facebook::before {
    content: "\eb43";
  }
  .icon.icon-whatsapp-2::before {
    content: "\eb70";
  }
}
/*! CSS Used from: https://uktravelroute.co.uk/wp-content/plugins/elementskit-lite/widgets/init/assets/css/widget-styles.css?ver=3.2.8 ; media=all */
@media all {
  :focus {
    outline: 0;
  }
  .elementskit-menu-container {
    z-index: 10000;
  }
  .elementskit-navbar-nav-default .elementskit-navbar-nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-left: 0;
  }
  .elementskit-navbar-nav-default .elementskit-navbar-nav > li {
    position: relative;
  }
  .elementskit-navbar-nav-default .elementskit-navbar-nav > li > a {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
  }
  .elementskit-navbar-nav-default .elementskit-navbar-nav > li > a:hover {
    text-decoration: none;
  }
  .elementskit-navbar-nav-default .elementskit-navbar-nav > li > a {
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    text-transform: none;
    font-weight: 500;
    letter-spacing: normal;
  }
  .elementskit-navbar-nav-default .elementskit-nav-identity-panel {
    display: none;
  }
  .elementskit-navbar-nav-default .elementskit-menu-close {
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: rgba(51, 51, 51, 0.5);
    float: right;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .elementskit-navbar-nav-default.elementskit-menu-container {
    background: rgba(255, 255, 255, 0);
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(255, 255, 255, 0)),
      to(rgba(255, 255, 255, 0))
    );
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0) 100%
    );
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    position: relative;
    height: 100px;
    z-index: 90000;
  }
  .elementskit-navbar-nav-default .elementskit-navbar-nav {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .elementskit-navbar-nav-default .elementskit-navbar-nav > li > a {
    font-size: 15px;
    color: #000;
    padding-left: 15px;
    padding-right: 15px;
  }
  .elementskit-navbar-nav-default
    .elementskit-navbar-nav.elementskit-menu-po-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  @media (max-width: 1024px) {
    .ekit_menu_responsive_tablet > .elementskit-navbar-nav-default {
      background-color: #f7f7f7;
    }
    .elementskit-navbar-nav-default.elementskit-menu-offcanvas-elements {
      width: 100%;
      position: fixed;
      top: 0;
      left: -100vw;
      height: 100%;
      -webkit-box-shadow: 0 10px 30px 0 rgba(255, 165, 0, 0);
      box-shadow: 0 10px 30px 0 rgba(255, 165, 0, 0);
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-transition: left 0.6s cubic-bezier(0.6, 0.1, 0.68, 0.53);
      transition: left 0.6s cubic-bezier(0.6, 0.1, 0.68, 0.53);
    }
    .elementskit-navbar-nav-default .elementskit-nav-identity-panel {
      display: block;
      position: relative;
      z-index: 5;
      width: 100%;
    }
    .elementskit-navbar-nav-default
      .elementskit-nav-identity-panel
      .elementskit-site-title {
      float: left;
    }
    .elementskit-navbar-nav-default
      .elementskit-nav-identity-panel
      .elementskit-menu-close {
      float: right;
    }
    .elementskit-navbar-nav-default .elementskit-navbar-nav > li > a {
      color: #000;
      font-size: 12px;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 5px;
      padding-bottom: 5px;
    }
    .elementskit-navbar-nav-default .elementskit-navbar-nav > li > a:hover {
      color: #0d3a4f;
    }
    .elementskit-navbar-nav-default .elementskit-navbar-nav > li:hover > a {
      color: #0d3a4f;
    }
  }
  @media only screen and (max-width: 1024px) and (min-width: 766px) {
    .ekit_menu_responsive_tablet
      .elementskit-navbar-nav-default
      .elementskit-navbar-nav {
      overflow-y: auto;
    }
  }
  .elementskit-menu-close,
  .elementskit-menu-hamburger {
    display: none;
  }
  .elementskit-menu-hamburger {
    color: #000;
  }
  @media (max-width: 1024px) {
    .elementskit-menu-overlay {
      display: block;
      position: fixed;
      z-index: 14;
      top: 0;
      left: -100%;
      height: 100%;
      width: 100%;
      background-color: rgba(51, 51, 51, 0.5);
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      opacity: 1;
      visibility: visible;
      -webkit-transition: all 0.6s cubic-bezier(0.6, 0.1, 0.68, 0.53) 0.4s;
      transition: all 0.6s cubic-bezier(0.6, 0.1, 0.68, 0.53) 0.4s;
    }
    .elementskit-menu-hamburger {
      border: 1px solid rgba(0, 0, 0, 0.2);
      float: right;
    }
    .elementskit-menu-hamburger .elementskit-menu-hamburger-icon {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .elementskit-menu-close,
    .elementskit-menu-hamburger {
      padding: 8px;
      background-color: transparent;
      border-radius: 0.25rem;
      position: relative;
      z-index: 10;
      cursor: pointer;
      width: 45px;
      -webkit-transition: all 0.4s ease-in;
      transition: all 0.4s ease-in;
    }
    .elementskit-menu-hamburger .elementskit-menu-hamburger-icon {
      height: 1px;
      width: 100%;
      display: block;
      margin-bottom: 4px;
    }
    .elementskit-menu-hamburger .elementskit-menu-hamburger-icon:last-child {
      margin-bottom: 0;
    }
  }
  .ekit-menu-nav-link {
    position: relative;
  }
  .elementskit-nav-logo {
    display: inline-block;
  }
  @media (max-width: 1024px) {
    .elementor-widget-ekit-nav-menu {
      -webkit-animation: none !important;
      animation: none !important;
    }
    .ekit-wid-con:not(.ekit_menu_responsive_mobile) .elementskit-navbar-nav {
      display: block;
    }
  }
  @media (max-width: 1024px) {
    .elementskit-menu-close,
    .elementskit-menu-hamburger {
      display: block;
    }
    .elementskit-menu-container {
      max-width: 350px;
    }
    .elementskit-menu-offcanvas-elements {
      height: 100% !important;
      padding-bottom: 10px;
    }
  }
  @media (max-width: 767px) {
    .ekit_menu_responsive_tablet
      .elementskit-navbar-nav-default
      .elementskit-navbar-nav {
      overflow-y: auto;
    }
  }
  @media (min-width: 1025px) {
    .ekit_menu_responsive_tablet .ekit-nav-menu--overlay {
      display: none;
    }
  }
  .ekit-wid-con a,
  .ekit-wid-con div,
  .ekit-wid-con li,
  .ekit-wid-con ul {
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }
  .ekit-wid-con a:active,
  .ekit-wid-con a:focus {
    -moz-outline: none;
    outline: 0;
  }
  .ekit-wid-con img:not([draggable]) {
    max-width: 100%;
    height: auto;
  }
  .ekit-wid-con a,
  .ekit-wid-con button {
    text-decoration: none;
    outline: 0;
  }
  .ekit-wid-con a:active,
  .ekit-wid-con a:focus,
  .ekit-wid-con a:hover,
  .ekit-wid-con a:visited {
    text-decoration: none;
    outline: 0;
  }
  .ekit-wid-con img {
    border: none;
    max-width: 100%;
  }
  .ekit-wid-con li,
  .ekit-wid-con ul {
    margin: 0;
    padding: 0;
  }
  .ekit-wid-con button:focus {
    outline: 0;
  }
  .ekit-wid-con .elementskit-navbar-nav-default.elementskit-menu-container {
    z-index: 1000;
  }
  .ekit-wid-con
    .elementskit-navbar-nav-default
    .elementskit-navbar-nav
    > li
    > a:hover {
    background-color: transparent;
  }
  .ekit-wid-con img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ekit-wid-con button {
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .elementor-widget-container .ekit-wid-con a {
    text-decoration: none;
  }
}
/*! CSS Used keyframes */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
  }
}
@keyframes elementor-headline-drop-in-in {
  0% {
    opacity: 0;
    transform: translateZ(100px);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes elementor-headline-drop-in-out {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }
  to {
    opacity: 0;
    transform: translateZ(-100px);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes elementor-animation-bob-float {
  100% {
    transform: translateY(-8px);
  }
}
@keyframes elementor-animation-bob {
  0% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(-8px);
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt5D4hTxM.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt7j4hTxM.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHt4D4h.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oDd4iYl.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73ord4iYl.woff2)
    format("woff2");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF,
    U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
    U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw73oTd4g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: elementskit;
  src: url(https://uktravelroute.co.uk/wp-content/plugins/elementskit-lite/modules/elementskit-icon-pack/assets/fonts/elementskit.woff?y24e1e)
    format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.form-group {
  float: left;
  width: 49%;
}

.form-group label {
  font-size: 14px;
  margin-bottom: 10px;
  color: #fff;
  text-align: left;
  float: left;
  margin-left: 20px;
}
.title-container {
  background: #F7E000;
  padding: 15px 40px 0 25px;
  height: 83px;
  position: relative;
  margin-top: -83px;
  float: left;
  width: 100%;
}
.title-container .search-title {
  margin: 0;
  font-weight: bold;
  font-size: 22px;
  text-align: left;
}
.title-container p {
  text-align: left;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 5px;
}
.banner-right .nav {
  /* display: flex;
  flex-wrap: wrap; */
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  float: left;
}
.banner-right .nav-tabs .nav-link {
  border: 1px solid transparent;
}
.banner-right  .nav-link {
  display: block;
  padding: 0.5rem 2rem;
  margin-left: -1px;
}
.banner-right .nav-tabs {
  border-bottom: none;
}

.banner-right .nav-item {
  background: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  margin-right: 1px;
}

.banner-right .nav-item a {
  color: #000;
}

.banner-right .nav-item:hover a {
  border-color: transparent !important;
}

.banner-right .nav-tabs .nav-link {
  border-radius: 0 !important;
}

.banner-right .tab-content {
  background: #2462D1;
}

.banner-right .form-wrap {
  padding: 29px 18px 25px 8px;
  text-align: center;
}

#myTabContent {
  float: right;
  width: 455px;
}

.banner-right .form-wrap .form-control, .react-datepicker-wrapper {
  padding: 0.675rem 0.75rem;
  font-size: 13px;
  font-weight: 300;
  border-radius: 0px;
  width: 191px;
  background: #fff;
  float: left;
  font-size: 15px;
  color: #000;
  margin-left: 19px;
  margin-bottom: 22px;
  height: 43px;
  border: none;
  font-size: 14px;
}


.banner-right .form-wrap .form-control:focus {
  box-shadow: none;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.marginRightZero {margin-right:0px !important}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.show {
  opacity: 1;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
color: #fff;
  background-color: #2462D1;
  border-color: #dee2e6 #dee2e6 #2462D1;
}
.primary-btn {
  background: #F7E000 !important;
  line-height: 42px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border: none !important;
  color: black !important;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  position: relative;
  border-radius: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  width: auto;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
@media (min-width: 768px) {
  .elementor-161 .elementor-element.elementor-element-32eea1ab {
    width: 82.743%;
  }
}
.elementor-107 .elementor-element.elementor-element-bac71f5, .elementor-107 .elementor-element.elementor-element-1dd8048 {display:none}

@media (max-width: 1189px) {
  .banner-right .form-wrap .form-control {
    width: 100%;
  }
}

.react-datepicker__navigation {
  padding: 0px !important;
  width: 33px !important;
  background: transparent !important;
  border: none;
  color: #000 !important;
  font-size: 12px !important;
  border-radius: 0px !important;
  display: flex !important;
}

.react-datepicker__current-month {
  font-size: 12px !important;
}
.react-datepicker-wrapper input {
  border: none !important;
  padding: 0px;
}
.destination {
  width: 401px !important;
  border: none;
}
.clearLeft {
  clear: left;
}

.submit-booking {
  padding: 10px 18px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
}
.pass-type {
  margin-right: 10px;
  display: none !important
}
@media (max-width: 468px) {
.banner-right .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  float: none;
  position: absolute;
  left: 0;
  top: 0;
}
#myTabContent {
  width: 66%;
  float: left;
}
.banner-right .form-wrap {
  margin-top: 26px;
}
}